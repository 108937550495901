import { ToolTypes } from '@labradorsports/constants';
import { distanceFromLine, snapToLine } from '@labradorsports/utils';
import { Anchor, AnchorLine, PluginContext } from './api.js';

function findPerpendicularSnapLines(lines: AnchorLine[], anchor: Anchor, offset: Point): AnchorLine[] {
    const horizontalLines = lines.filter((line) => line.angle === 0 && line.piece.id !== anchor.id);
    const verticalLines = lines.filter((line) => line.angle === 90 && line.piece.id !== anchor.id);

    const horizontalLinesByDistance = horizontalLines.sort((line1, line2) => {
        return distanceFromLine(offset, line1.a, line1.b) - distanceFromLine(offset, line2.a, line2.b);
    });

    const verticalLinesByDistance = verticalLines.sort((line1, line2) => {
        return distanceFromLine(offset, line1.a, line1.b) - distanceFromLine(offset, line2.a, line2.b);
    });

    return [horizontalLinesByDistance[0], verticalLinesByDistance[0]].filter(
        (line) => Boolean(line) && distanceFromLine(offset, line.a, line.b) < 15
    );
}

export default {
    types: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER, ToolTypes.COACH],

    generateLines({ frame }) {
        return frame.players.flatMap((player) => {
            return [
                {
                    a: {
                        x: 0,
                        y: player.origin.y,
                    },
                    b: {
                        x: frame.field.width,
                        y: player.origin.y,
                    },
                    piece: player,
                },
                {
                    a: {
                        x: player.origin.x,
                        y: 0,
                    },
                    b: {
                        x: player.origin.x,
                        y: frame.field.height,
                    },
                    piece: player,
                },
            ];
        });
    },

    handleDragEnd: ({ api, frame, fieldViewport, lines }, anchor, offset) => {
        if (anchor.pointerType === 'keyboard' || typeof anchor.id !== 'number') {
            return;
        }

        const pixelOffset = fieldViewport.getPixelCoords(offset);
        const snapTo = findPerpendicularSnapLines(lines, anchor, pixelOffset);

        const snappedPoints = snapTo.map((line) => snapToLine(pixelOffset, line.a, line.b));

        if (snappedPoints.length > 0) {
            const snapPoint =
                snappedPoints.length === 1
                    ? snappedPoints[0]
                    : {
                          y: snappedPoints[0].y,
                          x: snappedPoints[1].x,
                      };

            api.updatePlayer(anchor.id, {
                type: anchor.type,
                origin: frame.field.clampCoords(fieldViewport.getFieldCoords(snapPoint)),
            });
        }

        api.showSnapLines([]);
    },

    handleDrag: ({ lines, fieldViewport, frame, api }, anchor, offset) => {
        if (anchor.pointerType === 'keyboard' || typeof anchor.id !== 'number') {
            return;
        }

        const pixelOffset = fieldViewport.getPixelCoords(offset);
        const snapTo = findPerpendicularSnapLines(lines, anchor, pixelOffset);

        const snappedPoints = snapTo.map((line) => snapToLine(pixelOffset, line.a, line.b));

        if (snappedPoints.length > 0) {
            const snapPoint =
                snappedPoints.length === 1
                    ? snappedPoints[0]
                    : {
                          y: snappedPoints[0].y,
                          x: snappedPoints[1].x,
                      };
            frame.updatePlayer(anchor.target, fieldViewport.getFieldCoords(snapPoint));

            api.showSnapLines(snapTo);
        } else {
            api.showSnapLines([]);
        }
    },
} as PlayPlugin<PluginContext>;
