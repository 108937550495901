import { useSelector } from 'react-redux';

import { useAssets } from '@labradorsports/assets';
import { RootState } from '../../../store/index.js';
import { getAssetUrl } from '../../utils.js';

interface Props {
    uid: string;
}

const ProfilePicture: FC<Props> = ({ uid }) => {
    const { AvatarIcon } = useAssets();
    const profileImageUrls = useSelector((state: RootState) => state.profile.profileImageUrls);
    const profileImageUrl = profileImageUrls[uid];

    return (
        <div className="profile-picture">
            <img src={profileImageUrl ?? getAssetUrl(AvatarIcon)} alt="avatar icon" />
        </div>
    );
};

export default ProfilePicture;
