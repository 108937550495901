export function getMidpoint(p1: Point, p2: Point): Point {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

export function distance(pt1: Point, pt2: Point): number {
    const a = pt1.x - pt2.x;
    const b = pt1.y - pt2.y;

    return Math.sqrt(a * a + b * b);
}

export function sortByDistance<T extends Point>(coords: Point, nodes: T[]): T[] {
    return nodes.sort((p1: T, p2: T) => distance(p1, coords) - distance(p2, coords));
}

export function findClosestNode<T extends Point>(coords: Point, nodes: T[], exclude: T[] = []): T {
    const filteredNodes = nodes.filter((node: T) => !exclude.includes(node));

    const sorted = sortByDistance(coords, filteredNodes);

    return sorted[0];
}

export function angleBetweenPoints(origin: Point, coords: Point): number {
    const height = coords.y - origin.y;
    const width = coords.x - origin.x;
    const angle = (Math.atan2(height, width) * 180) / Math.PI;

    return angle < 0 ? angle + 360 : angle;
}

export function rotateAroundOrigin(origin: Point, pt: Point, angle: number): Point {
    const convertedAngle = (angle * Math.PI) / 180;
    const x = pt.x - origin.x;
    const y = pt.y - origin.y;

    const newX = x * Math.cos(convertedAngle) - y * Math.sin(convertedAngle);
    const newY = x * Math.sin(convertedAngle) + y * Math.cos(convertedAngle);

    return {
        x: newX + origin.x,
        y: newY + origin.y,
    };
}

export function translateAtAngle(coords: Point, angle: number, dist: number): Point {
    const convertedAngle = angle * (Math.PI / 180);

    return {
        x: coords.x + dist * Math.cos(convertedAngle),
        y: coords.y + dist * Math.sin(convertedAngle),
    };
}

export function moveTowardsPointPercentage(from: Point, to: Point, percentage: number) {
    return {
        x: from.x + (to.x - from.x) * percentage,
        y: from.y + (to.y - from.y) * percentage,
    };
}

export function distanceFromLine(pt: Point, a: Point, b: Point): number {
    const dist = distance(a, b);

    const area = Math.abs((b.y - a.y) * pt.x - (b.x - a.x) * pt.y + b.x * a.y - b.y * a.x);

    return area / dist;
}

// This function can handle lines of any angle
// although in practice we (currently) only use horizontal or vertical lines
export function snapToLine(pt: Point, a: Point, b: Point): Point {
    const lineAngle = angleBetweenPoints(a, b);
    const angleToPoint = angleBetweenPoints(pt, b);
    const adjustedAngleToPoint = angleToPoint > 180 ? angleToPoint - 360 : angleToPoint;

    const perpendicularAngleAdjustment = lineAngle > adjustedAngleToPoint ? -90 : 90;

    return translateAtAngle(pt, lineAngle + perpendicularAngleAdjustment, distanceFromLine(pt, a, b));
}

export function moveTowardsPoint(from: Point, to: Point, dist: number): Point {
    return translateAtAngle(from, angleBetweenPoints(from, to), dist);
}

export function applyOffset(coords: Point, offset: Partial<Point>): Point {
    return {
        x: coords.x + (offset.x ?? 0),
        y: coords.y + (offset.y ?? 0),
    };
}

export function getCoordsOffset(origin: Point, coords: Point): Point {
    return {
        x: coords.x - origin.x,
        y: coords.y - origin.y,
    };
}

// t is the proportion of the curve that should occur before the midpoint
export function getBezierControlPoint(start: Point, mid: Point, end: Point, t = 0.5): Point {
    const [P0, P1, P2] = [start, mid, end].map((pt) => getCoordsOffset(start, pt));
    return {
        x: P1.x / (2 * t * (1 - t)) - (P0.x * t) / (2 * (1 - t)) - (P2.x * (1 - t)) / (2 * t) + start.x,
        y: P1.y / (2 * t * (1 - t)) - (P0.y * t) / (2 * (1 - t)) - (P2.y * (1 - t)) / (2 * t) + start.y,
    };
}

// P0, P1, P2 are either the x or y coordinate of the points
export function bez(P0: number, P1: number, P2: number, t: number): number {
    return (1 - t) ** 2 * P0 + 2 * (1 - t) * t * P1 + t ** 2 * P2;
}

export function getPointAlongBezier(start: Point, control: Point, end: Point, t: number): Point {
    return {
        x: bez(start.x, control.x, end.x, t),
        y: bez(start.y, control.y, end.y, t),
    };
}

// https://gamedev.stackexchange.com/questions/5373/moving-ships-between-two-planets-along-a-bezier-missing-some-equations-for-acce/5427#5427
export function getBezierDistanceFunction(start: Point, control: Point, end: Point): [number, (p: number) => Point] {
    let prev = start;

    const arclengths = [0];
    let arclength = 0;

    for (let a = 1; a <= 100; a += 1) {
        const bezierSegmentEnd = getPointAlongBezier(start, control, end, a * 0.01);

        arclength += distance(prev, bezierSegmentEnd);
        arclengths.push(arclength);
        prev = bezierSegmentEnd;
    }

    return [
        arclength,
        (p: number) => {
            // Binary search for performance over .findIndex
            let low = 0;
            let high = 100;
            let index = 0;

            while (low < high) {
                // | 0 is a faster way to truncate to integer part
                // eslint-disable-next-line no-bitwise
                index = low + (((high - low) / 2) | 0);
                if (arclengths[index] < p) {
                    low = index + 1;
                } else {
                    high = index;
                }
            }

            if (arclengths[index] > p) {
                index -= 1;
            }

            const bottom = arclengths[index];
            const top = arclengths[index + 1];

            const proportionBetween = (p - bottom) / (top - bottom);
            const t = (index + proportionBetween) / 100;
            return getPointAlongBezier(start, control, end, t);
        },
    ];
}

export function getWavePointsStraight(start: Point, end: Point, spacing: number, padding: number): Point[] {
    const angle = angleBetweenPoints(start, end);
    const dist = distance(start, end);
    const numberOfSegments = Math.max(Math.floor(dist / spacing) - padding * 2, 0);
    const points = [];
    for (let i = padding; i <= numberOfSegments + padding; i += 1) {
        points.push(translateAtAngle(start, angle, i * spacing));
    }

    return points;
}

export function getWavePointsCurved(
    start: Point,
    control: Point,
    end: Point,
    spacing: number,
    padding: number
): Point[] {
    const [bezierLength, getDistanceAlongBezier] = getBezierDistanceFunction(start, control, end);
    const numberOfSegments = Math.max(Math.floor(bezierLength / spacing) - padding * 2, 0);

    const points = [];

    for (let i = padding; i <= numberOfSegments + padding; i += 1) {
        points.push(getDistanceAlongBezier(i * spacing));
    }

    return points;
}

export function getPresetCoords(coords: Point[], center: Point, fieldWidth: number): Point[] {
    return coords.map((coord) => ({
        x: center.x - ((coord.x - 50) / 100) * fieldWidth,
        y: center.y + ((coord.y - 50) / 100) * fieldWidth,
    }));
}

export function getShadingCorners(center: Point, width: number, height: number): Point[] {
    if (!center) {
        return [];
    }

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    return [
        // Top left
        applyOffset(center, {
            x: -halfWidth,
            y: -halfHeight,
        }),
        // Top right
        applyOffset(center, {
            x: halfWidth,
            y: -halfHeight,
        }),
        // Bottom left
        applyOffset(center, {
            x: -halfWidth,
            y: halfHeight,
        }),
        // Bottom right
        applyOffset(center, {
            x: halfWidth,
            y: halfHeight,
        }),
    ];
}

export function getShadingColor(color: string): string {
    const isTransparent = color?.length === 9 && !color.toLowerCase().endsWith('ff');

    return isTransparent ? color : `${color.slice(0, 7)}38`;
}

export function resizeRectangle(
    origin: Point,
    width: number,
    height: number,
    rotation = 0,
    anchorStart: Point,
    anchorEnd: Point
): [Point, number, number] {
    // Update reference frame to 0 effective rotation
    const unrotatedAnchorStart = rotateAroundOrigin(origin, anchorStart, -rotation);
    const unrotatedHandle = rotateAroundOrigin(origin, anchorEnd, -rotation);

    // Use the relation between the starting corner and the center to determine how to modify center and w/h calculations
    const xMultiplier = unrotatedAnchorStart.x < origin.x ? -1 : 1;
    const yMultiplier = unrotatedAnchorStart.y < origin.y ? -1 : 1;

    // Find the opposite corner by calculating from the center based on the width and height
    const oppositeCorner = applyOffset(origin, {
        x: -xMultiplier * (width / 2),
        y: -yMultiplier * (height / 2),
    });

    // Use the opposite corner to find a new center assuming the ending point should be the new corner position
    const newCenter = getMidpoint(unrotatedHandle, oppositeCorner);

    // Calculate the amount the center has shifted
    const centerShift = getCoordsOffset(newCenter, origin);

    return [
        // Rotate the new center back into the correct reference frame using the old center
        rotateAroundOrigin(origin, newCenter, rotation),
        // Calculate new w/h based on movement of the center
        Math.abs(width - xMultiplier * centerShift.x * 2),
        Math.abs(height - yMultiplier * centerShift.y * 2),
    ];
}
