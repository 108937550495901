import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Button, ClickAwayListener } from '@mui/material';

import { ProfileSetupStep, ProgressBar, SocialIcon } from '@labradorsports/components';
import { ProfileFlags } from '@labradorsports/constants';
import { useSite } from '@labradorsports/utils';
import { RootState, Selectors } from '../../store/index.js';
import { logout as doLogout, setProfileFlag as doSetProfileFlag } from '../../store/async-actions.js';
import { useDispatcher } from '../../shared/hooks/index.js';

interface Props {
    open: boolean;
    onClose: AnyFunction;
    extraNavLinks: [string, string][];
}

const LoggedInMenu: FC<Props> = ({ onClose, extraNavLinks, open }) => {
    const { Config } = useSite();
    const logout = useDispatcher(doLogout);
    const setProfileFlag = useDispatcher(doSetProfileFlag);
    const onboardingSteps = useSelector(Selectors.onboardingSteps);
    const currentTeam = useSelector(Selectors.currentTeam);
    const firstName = useSelector(Selectors.firstName);
    const completedOnboardingSteps = useSelector(Selectors.completedOnboardingSteps);
    const hideOnboarding = useSelector(
        (state: RootState) => state.profile.profileFlags[ProfileFlags.HIDE_PROFILE_SETUP]
    );
    const navigate = useNavigate();
    const [activeIdx, setActiveIdx] = useState(0);

    useEffect(() => {
        const firstUnfinishedIdx = onboardingSteps.findIndex((step) => !completedOnboardingSteps[step]);

        if (firstUnfinishedIdx !== -1) {
            setActiveIdx(Math.min(firstUnfinishedIdx, onboardingSteps.length - 3));
        }
    }, [completedOnboardingSteps]);

    const navLinks = [['Account Info', '/profile/account']];

    if (Config.Features.TeamAdmin && !APP) {
        navLinks.push(['Team Administration', '/profile/teamadmin']);
    }

    if (Config.Features.TeamDocuments && currentTeam) {
        navLinks.push(['Team Documents', '/feed/documents/list']);
    }

    if (Config.Features.TeamAdmin && !APP) {
        navLinks.push(['Billing History', '/profile/billing']);
    }

    const navLink = ([text, to]: [string, string]) => (
        <NavLink className="menu-item" key={to} to={to}>
            {text}
        </NavLink>
    );

    const showAllProfileSteps = () => {
        navigate(hideOnboarding ? '/profile/account' : '/feed');
    };

    const completion =
        (onboardingSteps.filter((step) => completedOnboardingSteps[step]).length / onboardingSteps.length) * 100;

    return (
        <CSSTransition classNames="header-menu" timeout={200} mountOnEnter unmountOnExit in={open}>
            <ClickAwayListener onClickAway={onClose}>
                <div className="logged-in-menu">
                    <div className="d-md-none">{extraNavLinks.map(navLink)}</div>
                    {navLinks.map(navLink)}
                    {Config.Features.Community ? (
                        <NavLink className="menu-item" to="/community">
                            Community
                        </NavLink>
                    ) : null}
                    <Button className="bs logout-button" onClick={logout}>
                        Log Out
                    </Button>
                    <hr className="m-0" />
                    {completion < 100 ? (
                        <>
                            <div className="p-2">{firstName}, complete your profile:</div>
                            <ProgressBar progress={completion} />
                            <div className="profile-setup-steps">
                                <div className="step-carousel p-1">
                                    {onboardingSteps.slice(activeIdx, activeIdx + 3).map((type, idx) => (
                                        <ProfileSetupStep
                                            key={idx}
                                            type={type}
                                            number={idx + 1}
                                            completed={completedOnboardingSteps[type]}
                                            setProfileFlag={setProfileFlag}
                                            compact
                                        />
                                    ))}
                                </div>
                                <Button onClick={showAllProfileSteps}>Show All</Button>
                            </div>
                            <hr className="m-0" />
                        </>
                    ) : null}
                    <div className="d-md-none text-center px-4">
                        <SocialIcon type="facebook" />
                        <SocialIcon type="instagram" />
                        <SocialIcon type="twitter" />
                        <SocialIcon type="youtube" />
                    </div>
                </div>
            </ClickAwayListener>
        </CSSTransition>
    );
};

export default LoggedInMenu;
