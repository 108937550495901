import { useState, useRef, useEffect, createContext, useContext } from 'react';

import { useSite } from '@labradorsports/utils';
import { usePlayEditor } from './playEditor.js';
import { Sites } from '@labradorsports/constants';

export interface PlaybackSpeedSettings {
    preFramePause: number;
    frameDuration: number;
    postFramePause: number;
    totalDuration: number;
}

export interface PlaybackValue {
    playing: boolean;
    setPlaying: (val: boolean) => void;
    animating: boolean;
    setAnimating: (val: boolean) => void;
    totalDuration: number;
    playbackSpeed: number;
    playbackSpeedSettings: PlaybackSpeedSettings;
    setPlaybackSpeed: (val: number) => void;
    playCurrentFrame: () => void;
    showingPlaybackScreen: string;
    setShowingPlaybackScreen: (val: string) => void;
}

interface Props {
    forceAnimation?: boolean;
}

export const PlaybackScreens = {
    TITLE: 'TITLE',
    REPLAY: 'REPLAY',
};

export const PlaybackContext = createContext<PlaybackValue>(null);

export const usePlayback = (): PlaybackValue => {
    return useContext(PlaybackContext);
};

const defaultPlaybackSpeedSettings: {
    [k: number]: [number, number];
} = {
    1: [2700, 0.25],
    2: [1700, 0.4],
    3: [1400, 0.35],
    4: [800, 0.6],
    5: [600, 0.66],
};

const footballPlaybackSpeedSettings: {
    [k: number]: [number, number];
} = {
    1: [4200, 0.6],
    2: [2700, 0.65],
    3: [1700, 0.6],
    4: [1400, 0.75],
    5: [800, 0.8],
};

const getPlaybackSpeedSettings = (site: string, playbackSpeed: number, duration = 1): PlaybackSpeedSettings => {
    const playbackSpeedSettings =
        site === Sites.footballlab ? footballPlaybackSpeedSettings : defaultPlaybackSpeedSettings;
    const [baseTotalDuration, baseFramePercentage] = playbackSpeedSettings[playbackSpeed];

    const frameDuration = baseTotalDuration * baseFramePercentage;
    const framePause = (baseTotalDuration - frameDuration) / 2;
    const modifiedFrameDuration = frameDuration * duration;

    return {
        preFramePause: framePause,
        frameDuration: modifiedFrameDuration,
        postFramePause: framePause,
        totalDuration: modifiedFrameDuration + framePause * 2,
    };
};

export const PlaybackProvider: FC<Props> = ({ children, forceAnimation }) => {
    const { Config } = useSite();
    const { play, currentFrameIdx, setCurrentFrame, selectFrame, playData, currentFrame, fieldSetup } = usePlayEditor();
    const [playbackSpeed, setPlaybackSpeed] = useState(2);
    const [showingPlaybackScreen, setShowingPlaybackScreen] = useState(null);

    const integerFrameIdx = Math.floor(currentFrameIdx);
    const partialFramePercentage = currentFrameIdx - integerFrameIdx;
    const baseFrame = play?.frameSnapshots[integerFrameIdx];

    const playbackSpeedSettings = getPlaybackSpeedSettings(
        Config.SiteConfig.Site,
        playbackSpeed,
        currentFrame?.duration
    );
    const { frameDuration, totalDuration } = playbackSpeedSettings;

    const timeouts = useRef<number[]>([]);
    const [animating, setAnimating] = useState(forceAnimation);
    const [playing, _setPlaying] = useState(false);

    // On change of frame index, either play the current frame, or just change frames
    useEffect(() => {
        if (play && !playing) {
            setCurrentFrame(
                partialFramePercentage > 0 ? baseFrame?.generateNextFrame(null, partialFramePercentage) : baseFrame
            );
        }
    }, [play, playing, currentFrameIdx]);

    useEffect(() => {
        if (fieldSetup) {
            selectFrame(0);
        }
    }, [fieldSetup]);

    const stopCurrentFrame = () => {
        if (timeouts.current.length > 0) {
            timeouts.current.forEach((id) => {
                clearTimeout(id);
            });

            timeouts.current = [];
        }
    };

    const setPlaying = (val: boolean) => {
        if (val) {
            setShowingPlaybackScreen(null);

            if (integerFrameIdx === play.frames.length - 1) {
                selectFrame(0);

                // Allow time to reset to first frame before starting playback
                setTimeout(() => {
                    setAnimating(true);
                    _setPlaying(true);
                }, frameDuration);
            } else {
                if (currentFrameIdx !== integerFrameIdx) {
                    selectFrame(integerFrameIdx);
                }

                setAnimating(true);
                _setPlaying(true);
            }
        } else {
            _setPlaying(false);
            setAnimating(false);

            stopCurrentFrame();
        }
    };

    const playCurrentFrame = () => {
        if (!play) {
            return;
        }

        const currentFrame = play.frameSnapshots[currentFrameIdx];
        const nextFrame = play.frameSnapshots[currentFrameIdx + 1];

        if (!currentFrame) {
            return;
        }

        if (!animating) {
            setAnimating(true);
        }

        // newFrame will be modified directly in every step of this frame,
        // and then copied into setCurrentFrame to update the screen
        const newFrame = currentFrame.copy();
        newFrame.players.forEach((plr) => {
            newFrame.updatePlayer(plr.id, {
                rotation: null,
            });
        });

        if (currentFrame.slides?.length > 0) {
            // Show the next frame role during animation
            currentFrame.slides.forEach((slide: any) => {
                const nextSlide = nextFrame?.slides?.find((sl: any) => sl.target === slide.target);

                if (nextSlide) {
                    newFrame.setRole(slide.target, nextSlide.role);
                }
            });
        }

        if (nextFrame?.assignments) {
            newFrame.assignments = nextFrame.assignments;
        }

        setCurrentFrame(newFrame.copy());

        timeouts.current.push(
            window.setTimeout(() => {
                newFrame.players
                    .map((plr) => nextFrame?.getPlayer(plr.id))
                    .filter((nextPlayer) => typeof nextPlayer?.rotation === 'number')
                    .forEach((nextPlayer) => {
                        newFrame.updatePlayer(nextPlayer.id, {
                            rotation: nextPlayer.rotation,
                        });

                        setCurrentFrame(newFrame.copy());
                    });
            }, frameDuration / 2)
        );

        timeouts.current.push(
            window.setTimeout(() => {
                if (nextFrame || Config.SiteConfig.Site === Sites.footballlab) {
                    selectFrame(currentFrameIdx + 1);
                }

                if (nextFrame) {
                    setCurrentFrame(nextFrame);
                } else {
                    setPlaying(false);
                    setShowingPlaybackScreen(PlaybackScreens.REPLAY);
                }

                if (!playing) {
                    setAnimating(false);
                }
            }, totalDuration - 100)
        );
    };

    useEffect(() => {
        stopCurrentFrame();

        if (playing) {
            setShowingPlaybackScreen(null);
            playCurrentFrame();
        }
    }, [currentFrameIdx, playing]);

    // Update the current frame and stop playback whenever a new play is generated
    useEffect(() => {
        if (play) {
            setCurrentFrame(
                partialFramePercentage > 0 ? baseFrame.generateNextFrame(null, partialFramePercentage) : baseFrame
            );

            if (fieldSetup) {
                setShowingPlaybackScreen(null);
            }
        }

        setPlaying(false);
    }, [play]);

    useEffect(() => {
        setShowingPlaybackScreen(!play || play.fieldSetup ? null : PlaybackScreens.TITLE);
    }, [playData?.id]);

    return (
        <PlaybackContext.Provider
            value={{
                playing,
                setPlaying,
                animating,
                setAnimating,
                playCurrentFrame,
                playbackSpeedSettings,
                totalDuration,
                playbackSpeed,
                setPlaybackSpeed,
                showingPlaybackScreen,
                setShowingPlaybackScreen,
            }}
        >
            {children}
        </PlaybackContext.Provider>
    );
};
