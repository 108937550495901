import { Sites } from '@labradorsports/constants';
import lacrosse from './lib/lacrosse/index.js';
import basketball from './lib/basketball/index.js';
import football from './lib/football/index.js';

export const SportRenderingConfigs = {
    [Sites.lacrosselab]: lacrosse,
    [Sites.hoopslab]: basketball,
    [Sites.footballlab]: football,
};

export * from './lib/components/index.js';
export * from './lib/providers/index.js';
export * from './lib/models/index.js';
export * from './lib/utils.js';
export * from './lib/plugins/index.js';
