import { Fragment } from 'react';

import { arcPath, halfCirclePath, movePath } from '@labradorsports/utils';

/**
 * Field dimensions:
 *  70 x 120 (yards)
 *  */
const WomensFullFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage, viewOptions, optionalLineProps }) => {
    const fieldWidth = 70;
    const fieldHeight = 120;

    const fanHeight = Math.sqrt(60.5); // x^2 + x^2 = 8^2;

    const topGoalY = 15;
    const topGoalFan =
        movePath(
            fieldViewport.getPixelX(fieldWidth / 2 - fanHeight),
            fieldViewport.getPixelY(topGoalY + fanHeight - 3)
        ) +
        arcPath(
            fieldViewport.getPixelLength(9.5),
            true,
            fieldViewport.getPixelX(fieldWidth / 2 + fanHeight),
            fieldViewport.getPixelY(topGoalY + fanHeight - 3)
        );

    const bottomGoalY = 105;
    const bottomGoalFan =
        movePath(
            fieldViewport.getPixelX(fieldWidth / 2 - fanHeight),
            fieldViewport.getPixelY(bottomGoalY - fanHeight + 3)
        ) +
        arcPath(
            fieldViewport.getPixelLength(9.5),
            false,
            fieldViewport.getPixelX(fieldWidth / 2 + fanHeight),
            fieldViewport.getPixelY(bottomGoalY - fanHeight + 3)
        );

    return (
        <g className="field-backdrop">
            <g {...optionalLineProps}>
                {/* Optional field lines */}
                {viewOptions.fieldLines?.GOAL ? (
                    <>
                        <line
                            x1={fieldViewport.getPixelX(0)}
                            y1={fieldViewport.getPixelY(bottomGoalY)}
                            x2={fieldViewport.getPixelX(fieldWidth)}
                            y2={fieldViewport.getPixelY(bottomGoalY)}
                        />
                        <line
                            x1={fieldViewport.getPixelX(0)}
                            y1={fieldViewport.getPixelY(topGoalY)}
                            x2={fieldViewport.getPixelX(fieldWidth)}
                            y2={fieldViewport.getPixelY(topGoalY)}
                        />
                    </>
                ) : null}

                {viewOptions.fieldLines?.FOOTBALL ? (
                    <>
                        {[5, 10, 15, 25, 30, 35, 40, 45, 50, 55].map((yardsFromCenter) => (
                            <Fragment key={yardsFromCenter}>
                                {/* Left */}
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 - 7)}
                                    y1={fieldViewport.getPixelY(fieldHeight / 2 + yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 - 5)}
                                    y2={fieldViewport.getPixelY(fieldHeight / 2 + yardsFromCenter)}
                                />
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 - 7)}
                                    y1={fieldViewport.getPixelY(fieldHeight / 2 - yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 - 5)}
                                    y2={fieldViewport.getPixelY(fieldHeight / 2 - yardsFromCenter)}
                                />

                                {/* Right */}
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 + 5)}
                                    y1={fieldViewport.getPixelY(fieldHeight / 2 + yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 + 7)}
                                    y2={fieldViewport.getPixelY(fieldHeight / 2 + yardsFromCenter)}
                                />
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 + 5)}
                                    y1={fieldViewport.getPixelY(fieldHeight / 2 - yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 + 7)}
                                    y2={fieldViewport.getPixelY(fieldHeight / 2 - yardsFromCenter)}
                                />
                            </Fragment>
                        ))}
                    </>
                ) : null}
            </g>

            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 17.5) : fieldViewport.getPixelX(17.5)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(50) : fieldViewport.getPixelY(10)}
                transform={`rotate(${fieldViewport.flipped ? 0 : 180}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(30)})`}
            />
            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth - 17.5) : fieldViewport.getPixelX(17.5)}
                y={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelY(fieldHeight - 10)
                        : fieldViewport.getPixelY(fieldHeight - 50)
                }
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight - 30)})`}
            />

            {/* Field Outline */}
            <rect
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth) : fieldViewport.getPixelX(0)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight) : fieldViewport.getPixelY(0)}
                width={fieldViewport.getPixelLength(fieldWidth)}
                height={fieldViewport.getPixelLength(fieldHeight)}
                fill="none"
            />

            {/* Top Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(40)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(40)}
            />

            {/* Bottom Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(fieldHeight - 40)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight - 40)}
            />

            {/* Center */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 2.5)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 1.5)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 2.5)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 1.5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 + 2.5)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 1.5)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - 2.5)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 1.5)}
            />
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(fieldHeight / 2)}
                r={fieldViewport.getPixelLength(10)}
                fill="none"
            />

            {/* Top Goal */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 12)}
                y1={fieldViewport.getPixelY(topGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 12)}
                y2={fieldViewport.getPixelY(topGoalY)}
            />
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(12),
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth / 2 + 12)
                        : fieldViewport.getPixelX(fieldWidth / 2 - 12),
                    fieldViewport.getPixelY(topGoalY),
                    !fieldViewport.flipped
                )}
            />
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(topGoalY)}
                r={fieldViewport.getPixelLength(3)}
                fill="white"
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 3)}
                y1={fieldViewport.getPixelY(topGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - fanHeight)}
                y2={fieldViewport.getPixelY(topGoalY + fanHeight - 3)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 + 3)}
                y1={fieldViewport.getPixelY(topGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + fanHeight)}
                y2={fieldViewport.getPixelY(topGoalY + fanHeight - 3)}
            />
            <path fill="none" d={topGoalFan} />

            {/* Bottom Goal */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 12)}
                y1={fieldViewport.getPixelY(bottomGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 12)}
                y2={fieldViewport.getPixelY(bottomGoalY)}
            />
            <path
                fill="none"
                d={halfCirclePath(
                    fieldViewport.getPixelLength(12),
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth / 2 + 12)
                        : fieldViewport.getPixelX(fieldWidth / 2 - 12),
                    fieldViewport.getPixelY(bottomGoalY),
                    fieldViewport.flipped
                )}
            />
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(bottomGoalY)}
                r={fieldViewport.getPixelLength(3)}
                fill="white"
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 3)}
                y1={fieldViewport.getPixelY(bottomGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - fanHeight)}
                y2={fieldViewport.getPixelY(bottomGoalY - fanHeight + 3)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 + 3)}
                y1={fieldViewport.getPixelY(bottomGoalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + fanHeight)}
                y2={fieldViewport.getPixelY(bottomGoalY - fanHeight + 3)}
            />
            <path fill="none" d={bottomGoalFan} />

            {/* Substitution Area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 5)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 - 5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth + 2)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 - 5)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(fieldHeight / 2 + 5)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(fieldHeight / 2 + 5)}
            />

            <text
                dx={fieldViewport.flipped ? fieldViewport.getPixelX(0) - 5 : fieldViewport.getPixelX(fieldWidth) - 5}
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor="end"
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                LacrosseLab.com
            </text>
        </g>
    );
};

export default WomensFullFieldBackdrop;
