import { movePath } from '@labradorsports/utils';

/**
 * Field dimensions:
 *  30 x 33 (yards)
 *  */
const BoxHalfFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage, viewOptions, optionalLineProps }) => {
    const fieldWidth = 30;
    const fieldHeight = 33;
    const goalY = 28;

    return (
        <g className="field-backdrop">
            <g {...optionalLineProps}>
                {/* Optional field lines */}
                {viewOptions.fieldLines?.GOAL ? (
                    <line
                        x1={fieldViewport.getPixelX(0)}
                        y1={fieldViewport.getPixelY(goalY)}
                        x2={fieldViewport.getPixelX(fieldWidth)}
                        y2={fieldViewport.getPixelY(goalY)}
                    />
                ) : null}
            </g>

            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth * 0.75)
                        : fieldViewport.getPixelX(fieldWidth * 0.25)
                }
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight - 13) : fieldViewport.getPixelY(5)}
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight - 20.5)})`}
            />

            {/* Field Outline */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(0)}
                y2={fieldViewport.getPixelY(fieldHeight - 7)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(fieldHeight - 7)}
            />
            <line
                x1={fieldViewport.getPixelX(7)}
                y1={fieldViewport.getPixelY(fieldHeight)}
                x2={fieldViewport.getPixelX(fieldWidth - 7)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />

            {/* Bottom left */}
            <path
                fill="none"
                d={`${movePath(
                    fieldViewport.getPixelX(0),
                    fieldViewport.getPixelY(fieldHeight - 7)
                )} A ${fieldViewport.getPixelLength(7)} ${fieldViewport.getPixelLength(
                    7
                )} 45 0 0 ${fieldViewport.getPixelX(7)} ${fieldViewport.getPixelY(fieldHeight)}`}
            />
            {/* Bottom right */}
            <path
                fill="none"
                d={`${movePath(
                    fieldViewport.getPixelX(fieldWidth),
                    fieldViewport.getPixelY(fieldHeight - 7)
                )} A ${fieldViewport.getPixelLength(7)} ${fieldViewport.getPixelLength(
                    7
                )} -45 0 1 ${fieldViewport.getPixelX(fieldWidth - 7)} ${fieldViewport.getPixelY(fieldHeight)}`}
            />

            {/* Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(4)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(4)}
            />

            {/* Center */}
            <circle
                cx={fieldViewport.getPixelX(fieldWidth / 2)}
                cy={fieldViewport.getPixelY(0)}
                r={fieldViewport.getPixelLength(0.6)}
                fill="none"
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 0.6)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - 0.6)}
                y2={fieldViewport.getPixelY(1)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 + 0.6)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 0.6)}
                y2={fieldViewport.getPixelY(1)}
            />

            {/* Goal */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2 - 3)}
                y1={fieldViewport.getPixelY(goalY)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 3)}
                y2={fieldViewport.getPixelY(goalY)}
            />

            {/* Substitution Area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth - 2)}
                y2={fieldViewport.getPixelY(10)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 2)}
                y1={fieldViewport.getPixelY(10)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(10)}
            />

            <text
                dx={fieldViewport.flipped ? fieldViewport.getPixelX(0) - 5 : fieldViewport.getPixelX(fieldWidth / 2)}
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor={fieldViewport.flipped ? 'end' : 'middle'}
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                LacrosseLab.com
            </text>
        </g>
    );
};

export default BoxHalfFieldBackdrop;
