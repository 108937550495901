import {
    getAuth,
    User,
    signInWithEmailAndPassword,
    applyActionCode,
    verifyPasswordResetCode,
    confirmPasswordReset,
    EmailAuthProvider,
    sendEmailVerification as sendFirebaseEmailVerification,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword as updateFirebasePassword,
    signOut,
    deleteUser,
} from 'firebase/auth';
import ReactGA from 'react-ga4';

import { AuthenticationErrors, GeneralErrors, createException, getAuthError } from '@labradorsports/utils';
import { RootState } from '../state.js';
import { profileActions, playEditorActions } from '../index.js';
import { mainActions } from '../main/index.js';
import { asyncWrapper, TD, ThunkContext } from '../async-actions.js';
import { initProfile, updatePersonal } from '../profile/async-actions.js';
import { emptySplitApi } from '../api.js';
import { authActions } from './index.js';

export const LoginPopoverForms = {
    SIGNUP: 'SIGNUP',
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};

export const initAuth = () => {
    return async (dispatch: TD, getState: () => RootState, { logger, site }: ThunkContext) => {
        getAuth().onAuthStateChanged((user) => {
            const state = getState();

            dispatch(authActions.LoginChange(user));

            if (user) {
                const loggedIn = state.auth.user;
                if (user.uid === loggedIn?.uid) {
                    return;
                }

                logger.log('logged in', { uid: user.uid });

                if (PROD && DEPLOY) {
                    ReactGA.set({ userId: user.uid });

                    window._hsq.push([
                        'identify',
                        {
                            email: user.email,
                        },
                    ]);
                }

                dispatch(initProfile());
            }
        });
    };
};

export const login = (email: string, password: string) => {
    return asyncWrapper('login', (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('login attempt');

        dispatch(authActions.AccountFormError());
        signInWithEmailAndPassword(getAuth(), email.trim(), password).catch((error) => {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                return dispatch(authActions.AccountFormError(exc));
            }

            throw exc;
        });
    });
};

export const logout = () => {
    return async (dispatch: TD, getState: () => RootState, { logger }: ThunkContext): Promise<any> => {
        dispatch(profileActions.PurgeProfile());

        dispatch(emptySplitApi.util.resetApiState());

        await signOut(getAuth());

        logger.log('logged out');
    };
};

export const sendEmailVerification = (user?: User) => {
    return (dispatch: TD, getState: () => RootState, { logger }: ThunkContext): Promise<any> => {
        const state = getState();

        const targetUser = user ?? state.auth.user;
        if (!targetUser) {
            logger.log('No user to send verification for');
            return null;
        }

        return sendFirebaseEmailVerification(targetUser);
    };
};

export const createAccount = (email: string, password: string, firstName: string, lastName: string) => {
    return asyncWrapper('createAccount', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        dispatch(authActions.StartSignup(firstName, lastName));

        if (state.playEditor.playModified) {
            dispatch(playEditorActions.PlayModified(false));
        }

        logger.log('createAccount');

        try {
            await new Promise<void>((resolve, reject) => {
                if (!window.grecaptcha) {
                    reject(createException(GeneralErrors.LOADING_ERROR, { details: 'No grecaptcha' }));

                    return;
                }

                window.grecaptcha.ready(function () {
                    window.grecaptcha
                        .execute(
                            PROD
                                ? '6LcSiOokAAAAAPGeVYT6n27e0-1TgzmCDKPT-1Hk'
                                : '6LdCi-okAAAAACgHuBNp7Wjn5EXgGf_v99n7aQ40',
                            { action: 'submit' }
                        )
                        .then(async function (token) {
                            try {
                                const userDetails = await cff.fetch('account/createAccount', null, {
                                    email: email.trim(),
                                    password,
                                    firstName,
                                    lastName,
                                    token,
                                });

                                logger.log('account created');

                                await dispatch(login(email.trim(), password));

                                dispatch(profileActions.PersonalUpdate(userDetails));

                                resolve();
                            } catch (err) {
                                reject(err);
                            }
                        });
                });
            });

            return true;
        } catch (error) {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return false;
            }

            throw exc;
        }
    });
};

export const sendPasswordReset = (email: string) => {
    return asyncWrapper('sendPasswordReset', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        logger.log('sendPasswordReset');

        try {
            await cff.fetch('account/sendPasswordReset', null, {
                email: email.trim(),
            });

            logger.log('send password reset complete');
            dispatch(mainActions.GenericAlert('A password reset link has been sent to your email address.'));
            return true;
        } catch (error) {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return false;
            }

            throw exc;
        }
    });
};

export const verifyEmail = (code: string) => {
    return asyncWrapper('verifyEmail', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('verifyEmail');

        try {
            await applyActionCode(getAuth(), code);
            logger.log('verify email complete');
            return true;
        } catch (error) {
            throw getAuthError(error);
        }
    });
};

export const validateResetPassword = (code: string) => {
    return asyncWrapper('validateResetPassword', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('validateResetPassword');

        try {
            const email = await verifyPasswordResetCode(getAuth(), code);

            logger.log('validate reset password complete');

            return email;
        } catch (error) {
            return false;
        }
    });
};

export const resetPassword = (code: string, email: string, newPassword: string) => {
    return asyncWrapper('resetPassword', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('resetPassword');

        try {
            await confirmPasswordReset(getAuth(), code, newPassword);

            logger.log('reset password complete');

            await dispatch(login(email.trim(), newPassword));

            dispatch(mainActions.GenericAlert('Your password has been updated'));
            return {
                success: true,
            };
        } catch (error) {
            return {
                error,
            };
        }
    });
};

export const updateEmailAddress = (newEmail: string) => {
    return asyncWrapper('updateEmailAddress', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const { user } = state.auth;

        logger.log('updateEmailAddress');

        try {
            await updateEmail(user, newEmail.trim());
            await dispatch(updatePersonal({ email: newEmail.trim() }));

            logger.log('updateEmailAddress complete');

            return null;
        } catch (error) {
            const exc = getAuthError(error);

            if (exc.isOfType(AuthenticationErrors)) {
                if (exc.is(AuthenticationErrors.REQUIRES_RECENT_LOGIN)) {
                    dispatch(authActions.Reauthenticating(true));
                } else {
                    dispatch(authActions.AccountFormError(exc));
                }
                return exc;
            }

            throw exc;
        }
    });
};

export const reauthenticate = (password: string) => {
    return asyncWrapper('reauthenticate', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('reauthenticate attempt');
        const { user } = state.auth;

        try {
            const credential = await EmailAuthProvider.credential(user.email, password);
            await reauthenticateWithCredential(user, credential);

            dispatch(authActions.Reauthenticating(false));
        } catch (error) {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return;
            }

            throw exc;
        }
    });
};

export const updatePassword = (currentPassword: string, newPassword: string) => {
    return asyncWrapper(
        'updatePassword',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            const { user } = state.auth;

            logger.log('updatePassword');

            try {
                await dispatch(reauthenticate(currentPassword));
                await updateFirebasePassword(user, newPassword);

                logger.log('updatePassword complete');

                dispatch(mainActions.GenericAlert('Your password has been updated.'));
            } catch (error) {
                const exc = getAuthError(error);
                if (exc.isOfType(AuthenticationErrors)) {
                    dispatch(authActions.AccountFormError(exc));
                    return;
                }

                throw exc;
            }
        },
        { suppressError: true }
    );
};

export const deleteAccount = () => {
    return asyncWrapper(
        'deleteAccount',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            const { user } = state.auth;

            logger.log('deleteAccount');

            try {
                await deleteUser(user);
            } catch (error) {
                const exc = getAuthError(error);
                if (exc.is(AuthenticationErrors.REQUIRES_RECENT_LOGIN)) {
                    dispatch(authActions.Reauthenticating(true));
                } else {
                    throw exc;
                }
            }
        },
        { suppressError: true }
    );
};
