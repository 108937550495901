import { ToolBaseTypes } from '@labradorsports/constants';
import { Move } from '../../models/index.js';
import { Anchor, PluginContext } from '../../plugins/api.js';
import { createLines, defaultMove, findClosestPiece } from '../../utils.js';
import PlayConfig from '../play-config.js';

const { ToolTypes } = PlayConfig;

export default {
    baseType: ToolBaseTypes.PASS,
    types: [ToolTypes.PASS, ToolTypes.SNAP],
    generateAnchors: ({ play, frame }) => {
        const anchors: Anchor[] = [];

        if (!play.fieldSetup && frame.passes) {
            anchors.unshift(
                ...frame.passes.map((pass) => {
                    return {
                        type: pass.type,
                        id: pass.id,
                        target: pass.target,
                        ...pass.anchors[0],
                    };
                })
            );
        }

        return anchors;
    },

    generateLines: ({ play, frame }) => {
        if (!play.fieldSetup && frame.passes) {
            return frame.passes.flatMap((pass) => {
                return createLines(pass);
            });
        }

        return [];
    },

    handleDragEnd: ({ api, play }, anchor, offset) => {
        // Snap always happens on frame 1
        const frameIdx = anchor.type === ToolTypes.PASS ? 2 : 1;
        const frame = play.frameSnapshots[frameIdx];
        // Pass ID will use ball ID when left blank
        const closestCarrier = findClosestPiece(offset, frame.hasBall());
        const closestBalls = frame.getBallsByCarrier(closestCarrier.id);

        if (frame.balls.length === 1) {
            const closestPassable = frame.findClosestPassable(offset, anchor.type, frame.hasBall(), closestBalls[0].id);

            if (closestPassable) {
                api.updatePass(
                    {
                        ball: closestBalls[0].id,
                        type: anchor.type,
                        target: closestPassable.id,
                        start: anchor.type === ToolTypes.PASS ? 1 : 0,
                    },
                    frameIdx
                );
            }
        } else if (typeof anchor.id === 'number') {
            const pass = frame.passes.find((p) => p.id === anchor.id);
            const closestPassable = frame.findClosestPassable(offset, anchor.type, [], pass.props.ball);

            api.updatePass(
                {
                    id: anchor.id,
                    target: closestPassable.id,
                    start: anchor.type === ToolTypes.PASS ? 1 : 0,
                },
                frameIdx
            );
        } else {
            const targetBall = closestBalls.find((ball) => !frame.passes?.some((pass) => pass.props.ball === ball.id));

            api.updatePass(
                {
                    ball: (targetBall ?? closestBalls[0]).id,
                    type: anchor.type,
                    target: null,
                    start: anchor.type === ToolTypes.PASS ? 1 : 0,
                },
                frameIdx
            );
        }
    },

    handleDrag({ play }, anchor, offset, clamped) {
        // Snap always happens on frame 1
        const frameIdx = anchor.type === ToolTypes.PASS ? 2 : 1;
        const frame = play.frameSnapshots[frameIdx];

        const pass = frame.passes.find((p) => p.id === anchor.id);
        const closest = frame.findClosestPassable(clamped, anchor.type, [], pass.props.ball);

        frame.updatePass(anchor.id, anchor.type, closest.id);
    },

    getDefaultMove({ play }, type, offset) {
        // Snap always happens on frame 1
        const frameIdx = type === ToolTypes.PASS ? 2 : 1;
        const frame = play.frameSnapshots[frameIdx];

        const closestCarrier = findClosestPiece(offset, frame.hasBall());

        if (!closestCarrier) {
            return null;
        }

        if (frame.balls.length > 1) {
            return defaultMove(frame.field, closestCarrier, type);
        }

        const carrierBall = frame.getBallsByCarrier(closestCarrier.id)[0];
        const closestPassable = frame.findClosestPassable(offset, type, frame.hasBall(), carrierBall.id);

        if (!closestPassable) {
            return null;
        }

        let target = closestPassable.origin;
        const targetMove = frame.getMove(closestPassable.id);

        if (targetMove) {
            target = targetMove.anchors[targetMove.anchors.length - 1];
        }

        return new Move({
            type,
            origin: closestCarrier.origin,
            anchors: [target],
            start: type === ToolTypes.PASS ? 1 : 0,
        });
    },
} as PlayPlugin<PluginContext>;
