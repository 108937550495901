import { serverTimestamp, doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL, getStorage, uploadBytes } from 'firebase/storage';

import { NotificationTypes } from '@labradorsports/constants';
import { GeneralErrors, createException, createNestedUpdates } from '@labradorsports/utils';
import firestore from '../../firebase-config.js';
import { getNotificationPermission, onTokenRefresh } from '../../app/plugins/index.js';
import { RootState } from '../state.js';
import { profileActions } from '../index.js';
import { playEditorActions } from '../index.js';
import { asyncWrapper, TD, ThunkContext } from '../async-actions.js';
import { logout } from '../auth/async-actions.js';
import { fetchTeams } from '../teams/async-actions.js';
import { trackUserProperties } from '../../shared/utils.js';

export const updatePersonal = (updates: any) => {
    return asyncWrapper('updatePersonal', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const nested = createNestedUpdates({ basicDetails: updates });

        logger.log('updatePersonal');

        await updateDoc(doc(firestore, 'users', state.auth.user.uid), nested);

        logger.log('updatePersonal complete');
        return dispatch(profileActions.PersonalUpdate(updates));
    });
};

export const updateSettings = (updates: any) => {
    return asyncWrapper('updateSettings', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const nested = createNestedUpdates({ settings: updates });

        logger.log('updateSettings');

        await updateDoc(doc(firestore, 'users', state.auth.user.uid), nested);

        logger.log('updateSettings complete');
        return dispatch(profileActions.SettingsUpdate(updates));
    });
};

export const loadProfileImage = (uid: string) => {
    return asyncWrapper(
        'loadProfileImage',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            try {
                const url = await getDownloadURL(ref(getStorage(), `users/${uid}/profile`));

                logger.log('loadProfileImage profileUrl fetch complete');
                dispatch(profileActions.ProfileImageURL(uid, url));
            } catch (error) {
                if (error.code === 'storage/object-not-found') {
                    logger.log('profileUrl fetch no image');
                } else {
                    logger.exception(createException(GeneralErrors.LOADING_ERROR, { nestedError: error }));
                }
            }
        },
        { showLoading: false }
    );
};

export const enableNotifications = (profileSettings: any) => {
    return asyncWrapper('enableNotifications', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const permission = await getNotificationPermission(logger);

        if (permission) {
            onTokenRefresh(async (deviceToken) => {
                if (
                    !profileSettings?.notifications?.[NotificationTypes.ALL] ||
                    !profileSettings?.notificationDevices?.[deviceToken]
                ) {
                    await dispatch(
                        updateSettings({
                            notifications: {
                                [NotificationTypes.ALL]: true,
                            },
                            notificationDevices: {
                                [deviceToken]: true,
                            },
                        })
                    );
                }
            });
        }
    });
};

export const initProfile = () => {
    return asyncWrapper(
        'initProfile',
        async (dispatch: TD, state: RootState, { logger, site }: ThunkContext) => {
            const { user } = state.auth;
            const { play } = state.playEditor;
            const unsavedPlay = play?.id.includes('__');

            if (unsavedPlay) {
                // If the user started creating a play, do not warn them about navigating away
                dispatch(playEditorActions.PlayModified(false));
            }

            logger.log('initProfile', { uid: user.uid });

            const userDoc = await getDoc(doc(firestore, 'users', user.uid));

            logger.log('initProfile user fetch complete');
            const val = userDoc.data();
            const promises: any[] = [];

            if (val) {
                const personalUpdates = [];
                if (!val.basicDetails.emailVerified && user.emailVerified) {
                    personalUpdates.push(['emailVerified', true]);
                }

                if (personalUpdates.length > 0) {
                    promises.push(dispatch(updatePersonal(Object.fromEntries(personalUpdates))));
                }

                dispatch(
                    profileActions.PersonalUpdate({
                        ...val.basicDetails,
                        createdDate: val.basicDetails.createdDate?.toDate(),
                        lastAccess: val.basicDetails.lastAccess?.toDate(),
                    })
                );

                dispatch(profileActions.SettingsUpdate(val.settings));

                dispatch(profileActions.ProfileFlagsSet(val.profileFlags ?? {}));

                promises.push(dispatch(fetchTeams()));

                promises.push(dispatch(loadProfileImage(user.uid)));

                // Using proper key name directly to maintain value of timestamp
                promises.push(
                    updateDoc(userDoc.ref, {
                        'basicDetails.lastAccess': serverTimestamp(),
                    })
                );

                await Promise.all(promises);
                logger.log('initProfile complete');

                if (unsavedPlay) {
                    dispatch(playEditorActions.PlayModified(true));
                }

                dispatch(profileActions.ProfileLoaded());

                if (APP) {
                    dispatch(enableNotifications(val.settings));
                }

                trackUserProperties(user.uid, {
                    site: site.Config.SiteConfig.Site,
                    email: val.basicDetails.email,
                    firstName: val.basicDetails.firstName,
                    lastName: val.basicDetails.lastName,
                });

                return null;
            }

            logger.exception(createException(GeneralErrors.NOT_FOUND));
            return dispatch(logout());
        },
        { retryable: true }
    );
};

export const uploadProfileImage = (file: any) => {
    return asyncWrapper('uploadProfileImage', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('uploadProfileImage');

        const { uid } = state.auth.user;
        const snapshot = await uploadBytes(ref(getStorage(), `users/${uid}/profile`), file);

        const url = await getDownloadURL(snapshot.ref);

        logger.log('uploadProfileImage complete');

        dispatch(profileActions.ProfileImageURL(uid, url));
    });
};

export const setProfileFlag = (flag: string, value: boolean) => {
    return asyncWrapper('setProfileFlag', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        if (state.auth.user) {
            const { uid } = state.auth.user;
            const { profileFlags } = state.profile;

            if (profileFlags?.[flag] !== value) {
                logger.log('setProfileFlag', { uid, flag, value });

                const updates = {
                    [flag]: value,
                };

                await updateDoc(
                    doc(firestore, 'users', uid),
                    createNestedUpdates({
                        profileFlags: updates,
                    })
                );

                logger.log('setProfileFlag complete');

                dispatch(profileActions.ProfileFlagsSet(updates));
            }
        }
    });
};
