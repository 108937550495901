/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
    basicDetails: any;
    settings: any;
    profileImageUrls: {
        [uid: string]: string;
    };
    profileLoaded: boolean;
    profileFlags: any;
}

const profileDefaultState: ProfileState = {
    basicDetails: {},
    settings: {},
    profileImageUrls: {},
    profileLoaded: false,
    profileFlags: {},
};

const { reducer: profileReducer, actions: profileActions } = createSlice({
    name: 'profile',
    initialState: profileDefaultState,
    reducers: {
        PersonalUpdate: (state, action) => {
            Object.assign(state.basicDetails, action.payload);
        },

        SettingsUpdate: (state, action) => {
            Object.assign(state.settings, action.payload);
        },

        PurgeProfile: () => profileDefaultState,

        ProfileImageURL: {
            prepare: (uid: string, url: string) => ({ payload: { uid, url } }),
            reducer: (state, action: PayloadAction<{ uid: string; url: string }, string>) => {
                state.profileImageUrls[action.payload.uid] = action.payload.url;
            },
        },

        ProfileLoaded: (state) => {
            state.profileLoaded = true;
        },

        ProfileFlagsSet: (state, action) => {
            Object.assign(state.profileFlags, action.payload);
        },
    },
});

export { profileReducer, profileActions, profileDefaultState };
