import { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

import { StyledTextField } from '@labradorsports/components';
import { useSite } from '@labradorsports/utils';
import { HelmetWrapper } from '../../shared/structural/index.js';
import { useDispatcher } from '../../shared/hooks/index.js';
import { addValidation, useValidation } from '../../shared/providers/index.js';
import { RootState, Selectors } from '../../store/index.js';
import { sendPasswordReset as doSendPasswordReset, LoginPopoverForms } from '../../store/async-actions.js';

interface Props {
    changeForm?: (form?: string) => any;
}

const ForgotPasswordForm: FC<Props> = ({ changeForm }) => {
    const { Config } = useSite();
    const sendPasswordReset = useDispatcher(doSendPasswordReset);
    const accountFormError = useSelector(Selectors.accountFormError);
    const defaultEmail = useSelector((state: RootState) => state.auth.loginEmail ?? '');
    const [email, setEmail] = useState(defaultEmail);

    const { errors, submitted, setSubmitted, validateRef } = useValidation(
        () => ({
            email: !email,
        }),
        [email]
    );

    const emailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const confirmSendPasswordReset = async () => {
        if (await validateRef.current()) {
            setSubmitted(false);
            sendPasswordReset(email).then((result) => {
                if (result) {
                    changeForm();
                }
            });
        } else {
            setSubmitted(true);
        }
    };

    const keyPress = (event: any) => {
        if (event.key === 'Enter') {
            confirmSendPasswordReset();
        }
    };

    const showLogin = () => {
        changeForm(LoginPopoverForms.LOGIN);
    };

    const canonical = `${Config.SiteConfig.Url}/forgot-password`;

    return (
        <div className="signup-form">
            <HelmetWrapper title="Forgot Password">
                <link rel="canonical" href={canonical} />
                <meta property="og:url" content={canonical} />
            </HelmetWrapper>
            <h2>Forgot Your Password?</h2>
            <p>Enter your email below, and we will send you an email to reset your password and create a new one.</p>
            <StyledTextField
                value={email}
                onChange={emailChange}
                label="Email"
                onKeyPress={keyPress}
                error={submitted && errors.email}
                helperText={submitted && errors.email && 'Please enter an email address'}
                fullWidth
            />
            {accountFormError ? <span className="error-text">{accountFormError.message}</span> : undefined}
            <div className="row">
                <div className="col text-start">
                    <div className="small">Remember your password?</div>
                    <Button tabIndex={1} onClick={showLogin}>
                        Log In
                    </Button>
                </div>
                <div className="col-auto text-end">
                    <Button variant="contained" onClick={confirmSendPasswordReset} color="secondary">
                        Send Email
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default addValidation(ForgotPasswordForm);
