import {
    serverTimestamp,
    doc,
    deleteDoc,
    updateDoc,
    addDoc,
    collection,
    setDoc,
    getDoc,
    getDocs,
    DocumentSnapshot,
    query,
    orderBy,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import { Addons, JoinTypes, Plans, ProfileFlags } from '@labradorsports/constants';
import {
    createNestedUpdates,
    setCookie,
    getUserRole,
    createException,
    GeneralErrors,
    getCommonError,
    arrayModify,
} from '@labradorsports/utils';
import firestore from '../../firebase-config.js';
import { AffiliateCouponCookieName, DataTags } from '../../shared/constants.js';
import Logger from '../../shared/logger.js';
import { RootState, Selectors } from '../state.js';
import { billingApi, teamsActions } from '../index.js';
import { billingActions, mainActions, profileActions } from '../index.js';
import { asyncWrapper, TD, ThunkContext } from '../async-actions.js';
import { setProfileFlag } from '../profile/async-actions.js';
import { trackUserProperties } from '../../shared/utils.js';

export const deleteProgram = (programId: string) => {
    return asyncWrapper('deleteProgram', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const { programs, userTeams } = state.teams;

        logger.log('deleteProgram', { programId });

        // Delete the program - cloud functions will delete related data automatically
        await deleteDoc(doc(firestore, 'programs', programId));

        dispatch(
            teamsActions.TeamsLoaded(
                programs.filter((program) => program.id !== programId),
                userTeams.filter((team) => team.programId !== programId)
            )
        );
    });
};

export const fetchTeams = () => {
    return asyncWrapper(
        'fetchTeams',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            logger.log('fetchTeams');
            const { owned = [], joined = [], programs = [], pendingJoin = [] } = await cff.fetch('teams/retrieveTeams');

            const allEntities = owned.concat(joined).concat(programs);

            allEntities.forEach((entity: any) => {
                // eslint-disable-next-line no-param-reassign
                entity.createdDate = new Date(entity.createdDate);
            });

            logger.log('fetchTeams complete', {
                programs: programs.length,
                owned: owned.length,
                joined: joined.length,
                pendingJoin: pendingJoin.length,
            });

            const { uid } = state.auth.user;

            trackUserProperties(state.auth.user.uid, {
                ownedPrograms: programs.length,
                ownedTeams: owned.length,
                role: getUserRole(uid, programs, joined),
            });

            return dispatch(teamsActions.TeamsLoaded(programs, owned, joined, pendingJoin));
        },
        { retryable: true }
    );
};

export const createProgramSubscription = () => {
    return asyncWrapper(
        'createProgramSubscription',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            const { creatingProgram, creatingBilling } = state.billing;

            logger.log('createProgramSubscription');

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { logoFile, teams, ...teamConfig } = creatingProgram;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { couponProperties, ...billingConfig } = creatingBilling;

            try {
                const { entity, billingEntity } = await cff.fetch('teams/createProgramSubscription', null, {
                    teamSettings: {
                        teams: teams?.filter((team) => team.name !== '' && team.sport !== ''),
                        ...teamConfig,
                        ...billingConfig,
                    },
                });

                logger.log('createProgramSubscription complete', {
                    entityId: entity.id,
                    cardId: billingEntity.cardId,
                });

                await dispatch(fetchTeams());

                dispatch(billingApi.util.invalidateTags([{ type: DataTags.BILLING_ENTITY, id: entity.id }]));

                // Clear affiliate cookie as the coupon should only show up once
                setCookie(AffiliateCouponCookieName, '', new Date(0));

                return true;
            } catch (error) {
                if (error.code) {
                    throw createException(error);
                } else {
                    logger.exception(createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: error }));
                    dispatch(mainActions.GenericError(error));
                }

                return false;
            }
        },
        { suppressError: true }
    );
};

export const updateProgramTeams = (programId: string, oldTeams: any[], newTeams: any[], programAddons: any) => {
    return asyncWrapper('updateProgramTeams', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        const [added, modified] = newTeams.reduce(
            ([addedAcc, modifiedAcc], newTeam) => {
                if (!newTeam.id) {
                    return [[...addedAcc, newTeam], modifiedAcc];
                }

                const existing = oldTeams.find((tm) => tm.id === newTeam.id);

                if (
                    existing.name !== newTeam.name ||
                    existing.sport !== newTeam.sport ||
                    existing.collegiateTeam !== newTeam.collegiateTeam ||
                    existing.addons?.[Addons.PLAYERS] !== newTeam.addons?.[Addons.PLAYERS] ||
                    existing.addons?.[Addons.COACHES] !== newTeam.addons?.[Addons.COACHES]
                ) {
                    return [addedAcc, [...modifiedAcc, { ...newTeam }]];
                }

                return [addedAcc, modifiedAcc];
            },
            [[], []]
        );

        const removed = oldTeams.filter((oldTeam) => !newTeams.find((tm) => tm.id === oldTeam.id));

        const response = await cff.fetch('teams/updateProgramTeams', null, {
            programId,
            added,
            modified,
            removed,
            addons: programAddons,
            quantity: newTeams.length,
        });

        logger.log('updateProgramTeams done', response);

        if (response.success) {
            dispatch(billingApi.util.invalidateTags([{ type: DataTags.BILLING_ENTITY, id: programId }]));
        }

        return response;
    });
};

export const loadEntityLogo = (entityId: string) => {
    return asyncWrapper('loadEntityLogo', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        logger.log('loadEntityLogo', { entityId });

        const { userTeams, joinedTeams, programs } = state.teams;

        const entity =
            programs?.find((prg: any) => prg.id === entityId) ??
            (userTeams ?? [])
                .concat(joinedTeams ?? [])
                .find((team: any) => team.id === entityId || team.programId === entityId);

        const programId = entity?.programId ?? entity?.id ?? entityId;

        if (programId && !state.teams.entityLogoUrls[entityId]) {
            try {
                const url = await getDownloadURL(ref(getStorage(), `teams/${programId}/logo.png`));

                dispatch(teamsActions.EntityLogoUrlLoaded(entityId, url));

                logger.log('loadEntityLogo complete');
                return url;
            } catch (error) {
                if (error.code === 'storage/object-not-found') {
                    logger.log('loadEntityLogo no logo');
                } else {
                    logger.exception(getCommonError(error));
                }
            }
        }

        return null;
    });
};

export const loadEntityLogos = () => {
    return asyncWrapper('loadEntityLogos', (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const { programs, entityLogoUrls } = state.teams;
        const entities = (programs ?? []).filter((entity) => !entityLogoUrls[entity.id]);

        logger.log('loadEntityLogos', { length: entities.length });

        entities.map((program: any) => dispatch(loadEntityLogo(program.id)));
    });
};

export const updateProgram = (entityId: string, updates: any) => {
    return asyncWrapper('updateProgram', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        const { userTeams, programs } = state.teams;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { logo, logoFile, teams, addons, ...entityUpdates } = updates;

        logger.log('updateProgram', {
            entityId,
            entityUpdates,
            teams: teams?.map((team: Team) => team.id),
            addons,
        });

        const existing = programs.find((program) => program.id === entityId);

        if (teams || addons) {
            await dispatch(updateProgramTeams(entityId, existing.teams, teams ?? existing.teams, addons));

            await dispatch(fetchTeams());
        } else {
            dispatch(teamsActions.TeamsLoaded(arrayModify(programs, existing, entityUpdates), userTeams));
        }

        await updateDoc(doc(firestore, 'programs', entityId), entityUpdates);

        if (logo) {
            await cff.fetch('teams/updateEntityLogo', null, {
                entityId,
                logo,
            });

            logger.log('updateProgram logo uploaded');

            await dispatch(loadEntityLogo(entityId));
        }

        // Allow fetchTeams to handle team updates
        delete updates.teams;
        dispatch(teamsActions.TeamUpdated(entityId, updates));
    });
};

export const getTeamMembers = (teams?: string[]) => {
    return asyncWrapper(
        'getTeamMembers',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            logger.log('getTeamMembers');

            const teamIds = teams ?? Selectors.allTeams(state).map((tm: any) => tm.id);
            const programIds = state.teams.programs?.map((program) => program.id);

            if (teamIds.length > 0) {
                const result = await cff.fetch('teams/getTeamMembers', {
                    teams: teamIds,
                    programs: programIds,
                });

                logger.log('getTeamMembers complete');
                dispatch(teamsActions.MembersLoaded(result));
            }
        },
        { retryable: true }
    );
};

const joinTeamSuccess = async (dispatch: TD, state: RootState, logger: Logger, result: any) => {
    if (result.joinedTeams) {
        dispatch(
            profileActions.PersonalUpdate({
                joinedTeams: Object.assign(result.joinedTeams, state.profile.basicDetails.joinedTeams),
            })
        );

        const teamId = Object.keys(result.joinedTeams)[0];

        dispatch(teamsActions.SetActiveTeam(teamId));
    }

    await dispatch(fetchTeams());
    await dispatch(getTeamMembers());

    logger.log('joinTeam complete', { joinedAs: result.joinedAs, result });

    return result;
};

export const joinTeam = (teamKey: string) => {
    return asyncWrapper(
        'joinTeam',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            logger.log('joinTeam');

            const result = await cff.fetch('teams/joinTeam', null, { teamKey });

            return joinTeamSuccess(dispatch, state, logger, result);
        },
        { suppressError: true }
    );
};

export const removeTeamMembers = (entityId: string, memberIds: string[]) => {
    return asyncWrapper('removeTeamMembers', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        logger.log('removeTeamMembers', { entityId, memberIds });
        await cff.fetch('teams/removeTeamMembers', null, {
            entityId,
            memberIds,
        });

        logger.log('removeTeamMembers complete');
        return dispatch(teamsActions.MemberRemoved(entityId, memberIds));
    });
};

export const updatePlayers = (playerOrPlayers: any | any[]) => {
    return asyncWrapper('updatePlayers', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const { activeTeam } = state.teams;

        const players = Array.isArray(playerOrPlayers) ? playerOrPlayers : [playerOrPlayers];
        logger.log('updatePlayers', { teamId: activeTeam, players: players.length });

        const roster = state.teams.roster.slice();

        const updatedPlayers = await players.reduce(async (accumulator, { contacts, ...player }) => {
            const updated = await accumulator;

            if (player.id) {
                logger.log('updatePlayer existing', { teamId: activeTeam, id: player.id });
                const { id, ...update } = player;

                await updateDoc(doc(firestore, 'teams', activeTeam, 'roster', id), update);

                logger.log('updatePlayer existing complete');
                const idx = roster.findIndex((pl: any) => pl.id === id);

                roster.splice(idx, 1, player);

                return [...updated, player];
            }

            logger.log('updatePlayer new', { teamId: activeTeam });
            const snapshot = await addDoc(collection(firestore, 'teams', activeTeam, 'roster'), player);

            logger.log('updatePlayer new complete', { id: snapshot.id });

            const newPlayer = {
                ...player,
                id: snapshot.id,
            };

            roster.push(newPlayer);

            if (contacts?.length > 0) {
                logger.log('adding contacts', { contacts: contacts.length });

                await contacts.reduce(async (acc: any, contact: any) => {
                    await acc;

                    const fullContact = {
                        ...contact,
                        playerId: newPlayer.id,
                    };

                    const added = await addDoc(collection(firestore, 'teams', activeTeam, 'roster'), fullContact);

                    logger.log('added contact', { id: added.id });

                    roster.push({
                        ...fullContact,
                        id: added.id,
                    });
                }, Promise.resolve());
            }

            return [...updated, newPlayer];
        }, Promise.resolve([]));

        dispatch(teamsActions.RosterLoaded(roster));

        return updatedPlayers;
    });
};

export const saveDepthChart = (depth: any = {}, autosave = false) => {
    return asyncWrapper(
        'saveDepthChart',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            const { activeTeam } = state.teams;

            logger.log('saveDepthChart', { teamId: activeTeam });

            await setDoc(doc(firestore, 'teams', activeTeam, 'roster', 'depthChart'), depth);

            logger.log('saveDepthChart complete');
            return dispatch(teamsActions.DepthChartLoaded(depth));
        },
        {
            showLoading: !autosave,
        }
    );
};

export const removePlayers = (playerIds: string[]) => {
    return asyncWrapper('removePlayer', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        const { activeTeam, roster } = state.teams;

        logger.log('removePlayers', { activeTeam, playerIds });
        const { depthChart } = await cff.fetch('teams/removeTeamPlayers', null, {
            teamId: activeTeam,
            playerIds,
        });

        const updatedRoster = roster.filter((plr) => !playerIds.includes(plr.id));
        dispatch(teamsActions.RosterLoaded(updatedRoster));
        dispatch(teamsActions.DepthChartLoaded(depthChart));
    });
};

export const retrieveRoster = (teamId: string) => {
    return asyncWrapper(
        'retrieveRoster',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            logger.log('retrieveRoster', { teamId });
            const snapshot = await getDocs(collection(firestore, 'teams', teamId, 'roster'));

            const roster: any[] = [];
            let depthChart;
            snapshot.docs.forEach((rosterDoc) => {
                if (rosterDoc.id === 'depthChart') {
                    depthChart = rosterDoc.data();
                    return;
                }

                const player = rosterDoc.data();

                if (player) {
                    player.id = rosterDoc.id;
                    player.joinedAt = player.joinedAt ? player.joinedAt.toDate() : null;
                    roster.push(player);
                }
            });

            if (roster.length > 0 && Selectors.currentUserTeamRole(state) !== JoinTypes.PLAYER) {
                dispatch(setProfileFlag(ProfileFlags.INVITED_TEAM, true));
            }

            logger.log('retrieveRoster complete', { size: roster.length });

            return { roster, depthChart };
        },
        { retryable: true }
    );
};

export const loadRoster = () => {
    return asyncWrapper(
        'loadRoster',
        async (dispatch: TD, state: RootState) => {
            if (!Selectors.canViewRoster(state)) return;

            const { roster, depthChart } = await dispatch(retrieveRoster(state.teams.activeTeam));

            dispatch(teamsActions.DepthChartLoaded(depthChart));

            dispatch(teamsActions.RosterLoaded(roster));

            if (!roster.find(({ id }) => id === state.teams.rosterPlayerId)) {
                dispatch(teamsActions.SetRosterPlayer(null));
            }
        },
        { retryable: true }
    );
};

function prepRatingSnap(snap: DocumentSnapshot) {
    const ratingDoc = snap.data();
    ratingDoc.id = snap.id;
    ratingDoc.timestamp = ratingDoc.timestamp.toMillis();
    return ratingDoc;
}

export const saveRating = (ratings: any, player: any) => {
    return asyncWrapper('saveRating', async (dispatch: TD, state: RootState, { logger, site }: ThunkContext) => {
        const { activeTeam, roster } = state.teams;

        logger.log('saveRating', { teamId: activeTeam, id: player.id });

        const playerRef = doc(firestore, 'teams', activeTeam, 'roster', player.id);

        const ratingsRef = await addDoc(collection(playerRef, 'ratings'), {
            timestamp: serverTimestamp(),
            overallRating: Selectors.playerRating(site.Config, player.position, ratings),
            ...ratings,
        });

        logger.log('saveRating rating add complete');
        const snap = await getDoc(ratingsRef);

        dispatch(teamsActions.SetTempRatings());
        dispatch(teamsActions.SetRatingsList([prepRatingSnap(snap), ...(state.teams.ratingsList ?? [])]));
        dispatch(teamsActions.SetActiveRatingId(snap.id));

        logger.log('saveRating update player');
        const overallRating = Selectors.playerRating(site.Config, player.position, ratings);
        await updateDoc(playerRef, {
            overallRating,
        });

        const playerIdx = roster.findIndex((pl) => pl.id === player.id);
        const newRoster = roster.slice();
        newRoster.splice(playerIdx, 1, {
            ...player,
            overallRating,
        });

        dispatch(teamsActions.RosterLoaded(newRoster));
    });
};

export const getRatings = (playerId: string) => {
    return asyncWrapper(
        'getRatings',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            const { activeTeam } = state.teams;

            if (!Selectors.teamViewable(state) || Selectors.currentPlan(state) === Plans.BASIC) return null;

            logger.log('getRatings', { teamId: activeTeam, id: playerId });

            const playerRef = doc(firestore, 'teams', activeTeam, 'roster', playerId);

            const snapshot = await getDocs(query(collection(playerRef, 'ratings'), orderBy('timestamp', 'desc')));

            logger.log('getRatings complete');
            return dispatch(teamsActions.SetRatingsList(snapshot.docs.map(prepRatingSnap)));
        },
        { retryable: true }
    );
};

export const inviteMembers = (
    entityId: string,
    playerEmails: string[],
    coachEmails: string[],
    adminEmails: string[]
) => {
    return asyncWrapper('inviteMembers', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        return cff.fetch('teams/inviteMembers', null, {
            entityId,
            playerEmails,
            coachEmails,
            adminEmails,
        });
    });
};

export const joinTeamLink = (entityId: string, linkCode: string) => {
    return asyncWrapper(
        'joinTeamLink',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            logger.log('joinTeamLink');

            const result = await cff.fetch('teams/joinTeamLink', null, {
                entityId,
                linkCode,
            });

            if (result.pending) {
                return result;
            }

            return joinTeamSuccess(dispatch, state, logger, result);
        },
        { suppressError: true }
    );
};

export const emailTeam = (emails: string[], subject: string, body: string) => {
    return asyncWrapper('emailTeam', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        const { roster } = state.teams;
        logger.log('emailTeam', { recipients: emails.length });

        const recipients = emails.map((email) => {
            const foundContact = roster.find((contact) => contact.email === email);

            return {
                email,
                firstName: foundContact?.firstName,
            };
        });

        await cff.fetch('teams/emailTeam', null, {
            recipients,
            subject,
            body,
        });
    });
};

export const setTeamFlags = (flags: any) => {
    return asyncWrapper('setTeamFlags', async (dispatch: TD, state: RootState) => {
        const { activeTeam } = state.teams;

        await updateDoc(
            doc(firestore, 'teams', activeTeam),
            createNestedUpdates({
                flags,
            })
        );

        dispatch(teamsActions.TeamFlagsSet(flags));
    });
};

export const setCoachPlaceholders = (coaches: any) => {
    return asyncWrapper('setCoachPlaceholders', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const { activeTeam, programs, userTeams, joinedTeams } = state.teams;
        const currentTeam = Selectors.currentTeam(state);

        logger.log('setCoachPlaceholders', { activeTeam });

        const teamUpdate = {
            coachPlaceholders: coaches,
        };

        await updateDoc(doc(firestore, 'teams', activeTeam), teamUpdate);

        dispatch(
            teamsActions.TeamsLoaded(
                programs,
                arrayModify(userTeams, currentTeam, teamUpdate),
                arrayModify(joinedTeams, currentTeam, teamUpdate)
            )
        );
    });
};

export const addCoachPlaceholder = (coach: any) => {
    return asyncWrapper('addCoachPlaceholder', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const currentTeam = Selectors.currentTeam(state);

        logger.log('addCoachPlaceholder', { activeTeam: currentTeam.id });

        await dispatch(setCoachPlaceholders((currentTeam.coachPlaceholders ?? []).concat([coach])));
    });
};

export const removeCoachPlaceholder = (idx: number) => {
    return asyncWrapper('removeCoachPlaceholder', async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
        const currentTeam = Selectors.currentTeam(state);

        logger.log('removeCoachPlaceholder', { activeTeam: currentTeam.id });

        await dispatch(setCoachPlaceholders(currentTeam.coachPlaceholders.filter((_: any, i: number) => i !== idx)));
    });
};

export const getInviteLink = (email: string, type: string, entityId: string) => {
    return asyncWrapper(
        'getInviteLink',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            logger.log('getInviteLink', { type, entityId });

            const { link } = await cff.fetch('teams/inviteLink', {
                email,
                type,
                entityId,
            });

            return link;
        },
        { retryable: true }
    );
};

export const getInvitePreview = (type: string, entityId: string, email?: string) => {
    return asyncWrapper(
        'getInvitePreview',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            logger.log('getInvitePreview', { type, entityId });

            const { html } = await cff.fetch('teams/previewInvite', {
                email,
                type,
                entityId,
            });

            return html;
        },
        { retryable: true }
    );
};

export const loadPendingRoster = () => {
    return asyncWrapper(
        'loadPendingRoster',
        async (dispatch: TD, state: RootState, { logger }: ThunkContext) => {
            if (!Selectors.canViewRoster(state) || !Selectors.teamViewable(state)) return null;

            const teamId = state.teams.activeTeam;

            logger.log('loadPendingRoster', { teamId });
            const snapshot = await getDocs(collection(firestore, 'teams', teamId, 'pendingRoster'));

            logger.log('loadPendingRoster complete');
            const roster: any[] = snapshot.docs.map((rosterDoc) => {
                const player = rosterDoc.data();

                if (player) {
                    player.id = rosterDoc.id;
                    player.joinedAt = player.joinedAt ? player.joinedAt.toDate() : null;
                }

                return player;
            });

            return dispatch(teamsActions.PendingRosterLoaded(roster));
        },
        { retryable: true }
    );
};

export const updatePendingRoster = (accept: string[], reject: string[], assign: any) => {
    return asyncWrapper(
        'updatePendingRoster',
        async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
            const teamId = state.teams.activeTeam;

            logger.log('updatePendingRoster', { teamId, accept, reject, assign });

            const result = await cff.fetch('teams/updatePendingMembers', null, {
                teamId,
                accept,
                reject,
                assign,
            });

            await Promise.all([dispatch(loadPendingRoster()), dispatch(loadRoster())]);

            if (!result.success) {
                mainActions.GenericError(
                    createException(GeneralErrors.UNKNOWN_ERROR, { details: 'Some updates were not successful' })
                );
            }
        }
    );
};

export const changeUserRole = (uid: string, id: string, newRole: string) => {
    return asyncWrapper('changeUserRole', async (dispatch: TD, state: RootState, { cff, logger }: ThunkContext) => {
        const { activeTeam } = state.teams;

        logger.log('changeUserRole', { activeTeam, uid, id, newRole });

        await cff.fetch('teams/changeUserRole', null, {
            teamId: activeTeam,
            uid,
            playerId: id,
            newRole,
        });

        await dispatch(loadRoster());
        await dispatch(getTeamMembers());
    });
};
