import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { angleBetweenPoints, getShadingCorners, rotateAroundOrigin } from '@labradorsports/utils';
import { resizeShading } from '../utils.js';
import { Anchor, PluginContext } from './api.js';

export default {
    baseType: ToolBaseTypes.PIECE,
    types: [ToolTypes.ELLIPSE, ToolTypes.RECTANGLE],
    specialAnchorTypes: [SpecialAnchorTypes.SHADING_RESIZE, SpecialAnchorTypes.SHADING_ROTATE],
    generateAnchors: ({ play, frame }) => {
        const anchors: Anchor[] = [];

        if (play.field.pieces) {
            const shadings = play.field.pieces.filter(
                (piece) =>
                    [ToolTypes.ELLIPSE, ToolTypes.RECTANGLE].includes(piece.type) &&
                    (piece.props?.onAllFrames || piece.props?.frameIdx === frame.idx)
            );

            // Center for movement
            anchors.unshift(
                ...shadings.map((piece) => ({
                    type: piece.type,
                    id: piece.id,
                    origin: piece.origin,
                    ...piece.origin,
                }))
            );
        }

        return anchors;
    },

    generateSelectedAnchors: ({ fieldViewport }, selected) => {
        const anchors: Anchor[] = [];

        if (![ToolTypes.ELLIPSE, ToolTypes.RECTANGLE].includes(selected.type)) {
            return [];
        }

        const base = {
            type: SpecialAnchorTypes.SHADING_RESIZE,
            id: selected.id,
            origin: selected.origin,
        };

        // Corners for resizing
        anchors.unshift(
            ...getShadingCorners(selected.origin, selected.props.width, selected.props.height).map(({ x, y }) => ({
                ...base,
                ...rotateAroundOrigin(selected.origin, { x, y }, selected.rotation ?? 0),
            }))
        );

        // Rotation handle
        anchors.unshift({
            type: SpecialAnchorTypes.SHADING_ROTATE,
            id: selected.id,
            ...rotateAroundOrigin(
                selected.origin,
                {
                    x: selected.origin.x,
                    y: selected.origin.y - selected.props.height / 2 - fieldViewport.getFieldLength(15),
                },
                selected.rotation ?? 0
            ),
            origin: selected.origin,
        });

        return anchors;
    },

    handleDragEnd: ({ api, frame, play, fieldViewport }, anchor, offset, clamped) => {
        if (anchor.type === SpecialAnchorTypes.SHADING_RESIZE) {
            const piece = play.field.getFieldPiece(anchor.id);
            const fieldAnc = fieldViewport.getFieldCoords(anchor);
            const [origin, width, height] = resizeShading(piece, fieldAnc, clamped);

            api.updateFieldPiece({
                id: anchor.id,
                origin,
                width,
                height,
            });
        } else if (anchor.type === SpecialAnchorTypes.SHADING_ROTATE) {
            const piece = frame.getFieldPiece(anchor.id);
            api.updateFieldPiece({
                id: anchor.id,
                rotation: angleBetweenPoints(piece.origin, offset) + 90,
            });
        } else {
            const update: any = {
                type: anchor.type,
                id: anchor.id,
                origin: clamped,
            };

            if (typeof anchor.id === 'undefined') {
                // Default onAllFrames if this is a new piece
                update.onAllFrames = play.fieldSetup;

                if (!update.onAllFrames) {
                    update.frameIdx = frame.idx;
                }
            }

            api.updateFieldPiece(update);
        }
    },

    handleDrag({ frame, fieldViewport }, anchor, offset, clamped) {
        const piece = frame.getFieldPiece(anchor.id);
        if (anchor.type === SpecialAnchorTypes.SHADING_RESIZE) {
            const fieldAnc = fieldViewport.getFieldCoords(anchor);
            const [origin, width, height] = resizeShading(piece, fieldAnc, clamped);

            frame.updateFieldPiece(anchor.id, origin, {
                width,
                height,
            });
        } else if (anchor.type === SpecialAnchorTypes.SHADING_ROTATE) {
            frame.rotateFieldPiece(anchor.id, angleBetweenPoints(piece.origin, offset) + 90);
        } else {
            frame.updateFieldPiece(anchor.id, offset);
        }
    },
} as PlayPlugin<PluginContext>;
