import { Sites, ToolTypes } from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';
import { applyOffset, getCoordsOffset } from '@labradorsports/utils';
import { PluginContext } from '../../plugins/api.js';

const { PlayerPositions } = SportConfigs[Sites.footballlab];

export default {
    types: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],

    handleDragEnd: ({ api, frame, play }, anchor, offset) => {
        const player = frame.getPlayer(anchor.id);

        if (player && player.props.role === PlayerPositions.CENTER) {
            const centerInitial = play.players.find((plr) => plr.id === anchor.id);
            const centerOffset = getCoordsOffset(centerInitial.origin, offset);

            frame.players.forEach((player) => {
                if (player.id === anchor.id) return;

                const initial = play.players.find((plr) => plr.id === player.id);

                api.updatePlayer(player.id, {
                    origin: play.field.clampCoords(applyOffset(initial.origin, centerOffset)),
                });
            });
        }
    },

    handleDrag: ({ play, frame }, anchor, offset) => {
        const player = frame.getPlayer(anchor.id);

        if (player && player.props.role === PlayerPositions.CENTER) {
            const centerInitial = play.players.find((plr) => plr.id === anchor.id);
            const centerOffset = getCoordsOffset(centerInitial.origin, offset);

            frame.players.forEach((player) => {
                if (player.id === anchor.id) return;

                const initial = play.players.find((plr) => plr.id === player.id);

                frame.updatePlayer(player.id, play.field.clampCoords(applyOffset(initial.origin, centerOffset)));
            });
        }
    },
} as PlayPlugin<PluginContext>;
