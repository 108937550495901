import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { angleBetweenPoints, translateAtAngle } from '@labradorsports/utils';
import { Anchor, PluginContext } from './api.js';
import FieldPiece from '../models/field-piece.js';

export default {
    baseType: ToolBaseTypes.PLAYER,
    types: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER, ToolTypes.COACH],
    specialAnchorTypes: [SpecialAnchorTypes.PLAYER_ROTATE],
    generateAnchors: ({ play, frame }) => {
        const anchors: Anchor[] = [];

        if (play.fieldSetup) {
            anchors.unshift(
                ...frame.players
                    .filter((atk: any) => typeof atk.props?.pieceId !== 'number')
                    .map((atk: any) => ({
                        target: atk.id,
                        ...atk,
                        ...atk.origin,
                    }))
            );
        } else {
            anchors.unshift(
                ...frame.players
                    .filter((atk: any) => typeof atk.props?.pieceId !== 'number')
                    .map((atk: any) => ({
                        target: atk.id,
                        ...atk,
                        ...atk.origin,
                        pointerType: 'pen',
                        type: SpecialAnchorTypes.PEN_PLAYER,
                    }))
            );

            anchors.unshift(
                ...frame.goalies.map((atk: any) => ({
                    target: atk.id,
                    ...atk,
                    ...atk.origin,
                    pointerType: 'pen',
                    type: SpecialAnchorTypes.PEN_PLAYER,
                }))
            );

            anchors.unshift(
                ...frame.players
                    .map((plr) => {
                        if (typeof plr.rotation === 'number') {
                            return {
                                ...plr,
                                ...plr.origin,
                                target: plr.id,
                                type: SpecialAnchorTypes.PLAYER_ROTATE,
                                ...translateAtAngle(plr.origin, plr.rotation, play.field.getDiagonal() / 40),
                            };
                        }

                        return null;
                    })
                    .filter(Boolean)
            );
        }

        return anchors;
    },

    handleDragEnd: ({ api, frame }, anchor, offset, clamped) => {
        if (anchor.type === SpecialAnchorTypes.PLAYER_ROTATE) {
            api.updatePlayerRotation({
                id: anchor.id,
                rotation: angleBetweenPoints(frame.getPlayer(anchor.id).origin, offset),
            });
        } else if (anchor.type === SpecialAnchorTypes.PEN_PLAYER) {
            // Do nothing for now
        } else {
            api.updatePlayer(anchor.id, {
                type: anchor.type,
                origin: {
                    x: clamped.x,
                    y: clamped.y,
                },
            });
        }
    },

    handleDrag: ({ frame }, anchor, offset) => {
        if (anchor.type === SpecialAnchorTypes.PLAYER_ROTATE) {
            frame.updatePlayerRotation(anchor.id, angleBetweenPoints(frame.getPlayer(anchor.id).origin, offset));
        } else if (anchor.type === SpecialAnchorTypes.PEN_PLAYER) {
            // Do nothing for now
        } else {
            frame.updatePlayer(anchor.id, offset);
        }
    },

    getDefaultMove({ play }, type, offset) {
        return new FieldPiece({
            type,
            origin: play.field.clampCoords(offset),
        });
    },
} as PlayPlugin<PluginContext>;
