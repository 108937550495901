import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { getShadingCorners, resizeRectangle } from '@labradorsports/utils';
import FieldPiece from '../models/field-piece.js';
import FieldViewport from '../models/field-viewport.js';
import { Anchor, PluginContext } from './api.js';

function resizeComment(
    piece: FieldPiece,
    fieldViewport: FieldViewport,
    anchorStart: Point,
    anchorEnd: Point
): [Point, number, number] {
    const [origin, width, height] = resizeRectangle(
        piece.origin,
        fieldViewport.getFieldLength(piece.props.width),
        fieldViewport.getFieldLength(piece.props.height),
        0,
        anchorStart,
        anchorEnd
    );

    return [origin, fieldViewport.getPixelLength(width), fieldViewport.getPixelLength(height)];
}

export default {
    baseType: ToolBaseTypes.PIECE,
    types: [ToolTypes.COMMENT],
    specialAnchorTypes: [SpecialAnchorTypes.COMMENT_RESIZE],
    generateAnchors: ({ play, frame }) => {
        const anchors: Anchor[] = [];

        if (!play.fieldSetup && frame.fieldComments) {
            anchors.unshift(
                ...frame.fieldComments.map((comment) => {
                    return {
                        id: comment.id,
                        type: ToolTypes.COMMENT,
                        ...comment.origin,
                    };
                })
            );
        }

        return anchors;
    },

    generateSelectedAnchors: ({ fieldViewport }, selected) => {
        if (selected.type !== ToolTypes.COMMENT) {
            return [];
        }

        const base = {
            type: SpecialAnchorTypes.COMMENT_RESIZE,
            id: selected.id,
            origin: selected.origin,
        };

        // Corners for resizing
        // width and height are measured in pixels here
        return getShadingCorners(
            selected.origin,
            fieldViewport.getFieldLength(selected.props.width),
            fieldViewport.getFieldLength(selected.props.height)
        ).map(({ x, y }) => ({
            ...base,
            x,
            y,
        }));
    },

    generateLines: ({ play, frame }) => {
        if (!play.fieldSetup && frame.fieldComments) {
            return frame.fieldComments.flatMap((comment) => {
                return [
                    {
                        a: { x: comment.origin.x, y: comment.origin.y - 15 },
                        b: { x: comment.origin.x, y: comment.origin.y + 15 },
                        move: comment,
                    },
                    {
                        a: { x: comment.origin.x - 15, y: comment.origin.y },
                        b: { x: comment.origin.x + 15, y: comment.origin.y },
                        move: comment,
                    },
                ];
            });
        }

        return [];
    },

    handleDragEnd: ({ api, frame, fieldViewport }, anchor, offset, clamped) => {
        if (anchor.type === SpecialAnchorTypes.COMMENT_RESIZE) {
            const piece = frame.fieldComments.find((comment) => comment.id === anchor.id);
            const fieldAnc = fieldViewport.getFieldCoords(anchor);
            const [origin, width, height] = resizeComment(piece, fieldViewport, fieldAnc, clamped);

            api.updateFieldComment({
                id: anchor.id,
                ...origin,
                width,
                height,
            });
            return;
        }

        api.updateFieldComment({ id: anchor.id, ...clamped });
    },

    handleDrag: ({ frame, fieldViewport }, anchor, offset, clamped) => {
        if (anchor.type === SpecialAnchorTypes.COMMENT_RESIZE) {
            const piece = frame.fieldComments.find((comment) => comment.id === anchor.id);
            const fieldAnc = fieldViewport.getFieldCoords(anchor);
            const [origin, width, height] = resizeComment(piece, fieldViewport, fieldAnc, clamped);

            frame.updateComment(anchor.id, origin, {
                width,
                height,
            });
            return;
        }

        frame.updateComment(anchor.id, clamped);
    },
} as PlayPlugin<PluginContext>;
