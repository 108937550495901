import { ToolTypes, Sites } from '@labradorsports/constants';
import {
    arraySlidingWindow,
    getSiteForSport,
    objectMerge,
    bezierCurvePath,
    linePath,
    wavyCurvePath,
    wavyLinePath,
    sortByDistance,
    moveTowardsPoint,
    translateAtAngle,
    distance,
    resizeRectangle,
    angleBetweenPoints,
    getMidpoint,
    getBezierControlPoint,
    rotateAroundOrigin,
    moveTowardsPointPercentage,
    getPointAlongBezier,
    getPresetCoords,
    applyOffset,
    getCoordsOffset,
    createException,
    PlayEditorErrors,
} from '@labradorsports/utils';
import { FieldPiece, FieldViewport, Field, Play, Move, FrameSnapshot } from './models/index.js';

export interface PlayData {
    initial: any[];
    balls: any[];
    frames: any[];
    id: string;
    name: string;
    fieldSetup: boolean;
    viewOptions: any;
    sport: string;
    type: string;
    tags: string[];
    description: string;
    fieldType: string;
    fieldConfig?: any;
    fieldPieces: any[];
    viewers?: any;
    version?: number;
    createdDate?: Date;
    lastUpdated?: Date;
    videoLink?: string;
    unifiedField?: boolean;
    branding?: {
        logoEntityId: string;
        colorPrimary: string;
        colorSecondary: string;
    };
    playbookId?: string;
    programId?: string;
}

export function findClosestPiece<T extends FieldPiece>(coords: Point, nodes: T[], exclude: T[] = []): T {
    const filteredNodes = nodes.filter((node: T) => !exclude.includes(node));

    const adaptedNodes = filteredNodes.map((node: T, idx: number) => ({ ...node.origin, originalIdx: idx }));
    const sorted = sortByDistance(coords, adaptedNodes);

    return sorted[0] ? filteredNodes[sorted[0].originalIdx] : null;
}

export function getLinePlayerPosition(
    lineOrigin: Point,
    playerCount: number,
    pos: number,
    fieldConfig: Field,
    rotation?: number
): Point {
    const dist = (playerCount - pos - 1) * (fieldConfig.getDiagonal() / 40);
    if (typeof rotation !== 'number') {
        return moveTowardsPoint(lineOrigin, fieldConfig.getCenter(), dist);
    }

    return translateAtAngle(lineOrigin, rotation, dist);
}

export function doesPlayerTouchBall(playerId: number, balls: any[], frames: any[]): boolean {
    const hasInitialBall = balls.map((ball) => ball.carrier);

    return (
        hasInitialBall.some((id) => id === playerId) ||
        frames.some((frame) => {
            return frame.passes?.some((pass: any) => pass.target === playerId);
        })
    );
}

export function doesPieceTouchBall(players: any[], balls: any[], frames: any[], piece: FieldPiece): boolean {
    if (
        piece.type === ToolTypes.BLUEPLAYER ||
        piece.type === ToolTypes.ORANGEPLAYER ||
        piece.type === ToolTypes.COACH
    ) {
        return doesPlayerTouchBall(piece.id, balls, frames);
    }

    const piecePlayers = players.filter((plr) => plr.props?.pieceId === piece.id);

    return (
        piecePlayers.some((plr) => doesPlayerTouchBall(plr.id, balls, frames)) ||
        frames.some((frame) => {
            return frame.shots?.find((shot: any) => shot.target === piece.id);
        })
    );
}

export function getLineEndpoints(line: FieldPiece, fieldViewport: FieldViewport): [Point, Point] {
    const playerCount = line.props.playerCount ?? 3;
    return [
        getLinePlayerPosition(line.origin, playerCount, playerCount + 1, fieldViewport.field, line.rotation),
        getLinePlayerPosition(line.origin, playerCount, -1, fieldViewport.field, line.rotation),
    ];
}

export function snapMoveToPlayerLine(
    frame: FrameSnapshot,
    fieldViewport: FieldViewport,
    coords: Point,
    targetId: number
): FieldPiece {
    const target = frame.getPlayer(targetId);

    const closestLine = frame.field.findClosestPiece(coords, [
        target.type === ToolTypes.BLUEPLAYER ? ToolTypes.OLINE : ToolTypes.DLINE,
    ]);

    if (closestLine && fieldViewport.getPixelLength(distance(closestLine.origin, coords)) < 30) {
        return closestLine;
    }

    return null;
}

export function initializePlay(PlayConfig: PlayConfigSpec, playData: any): Play {
    const { PlayTypes, FieldTypes, FieldSettings, GoalDesign } = PlayConfig;

    const { sport, fieldType, fieldConfig, type, fieldPieces, unifiedField } = playData;

    const goalDesign = GoalDesign[sport];

    if (fieldType) {
        const defaultConfig = FieldSettings[sport][unifiedField ? PlayTypes.FULL_FIELD : fieldType];

        if (!defaultConfig && !fieldConfig) {
            throw createException(PlayEditorErrors.INVALID_FIELD_CONFIG, {
                details: `sport: ${sport} fieldType: ${fieldType} playData: ${JSON.stringify(playData)}`,
            });
        }

        const { width, height, sideline } =
            type === PlayTypes.DRILL && fieldType === FieldTypes.CUSTOM ? fieldConfig : defaultConfig;

        const field = new Field(width, height, sideline, {
            fieldType,
            pieces: fieldPieces,
            goalDesign,
        });

        return new Play(PlayConfig, field, playData);
    }

    return new Play(PlayConfig, null, playData);
}

export interface ZoomOffset {
    x: number;
    y: number;
}

export function clampZoomOffset(fieldViewport: FieldViewport, coords: ZoomOffset): ZoomOffset {
    return {
        x: Math.min(Math.max(coords.x, 0), fieldViewport.w * fieldViewport.zoomFactor - fieldViewport.w),
        y: Math.min(Math.max(coords.y, 0), fieldViewport.h * fieldViewport.zoomFactor - fieldViewport.h),
    };
}

export function getStraightMoveDuration(move: Move, totalDuration: number, leg: number): number {
    if (!move || move.anchors.length === 1 || leg === -1) {
        return totalDuration;
    }

    const distances = arraySlidingWindow([move.origin, ...move.anchors], distance);
    const totalDist = distances.reduce((acc, dist) => acc + dist, 0);

    return distances.map((dist) => (dist / totalDist) * totalDuration)[leg];
}

export function resizeShading(shading: FieldPiece, anchorStart: Point, anchorEnd: Point): [Point, number, number] {
    return resizeRectangle(
        shading.origin,
        shading.props.width,
        shading.props.height,
        shading.rotation,
        anchorStart,
        anchorEnd
    );
}

export function negateOffset(offset: Point): Point {
    return {
        x: -offset.x,
        y: -offset.y,
    };
}

export function findBestPair(list: any[], predicate: (itemA: any, itemB: any) => number): [any, any] {
    let itemA = list[0];
    let itemB = list[1];
    let best = predicate(itemA, itemB);

    list.forEach((item, idx) => {
        for (let i = idx + 1; i < list.length; i += 1) {
            const rank = predicate(item, list[i]);
            if (rank > best) {
                itemA = item;
                itemB = list[i];
                best = rank;
            }
        }
    });

    return [itemA, itemB];
}

export function findFurthestPoints(points: Point[]): [Point, Point] {
    return findBestPair(points, (pointA, pointB) => distance(pointA, pointB));
}

export function centerOfMass(points: Point[]): Point {
    const [xSum, ySum] = points.reduce((accumulator, pt) => [accumulator[0] + pt.x, accumulator[1] + pt.y], [0, 0]);

    return {
        x: xSum / points.length,
        y: ySum / points.length,
    };
}

export function isPointAboveLine(pt: Point, a: Point, b: Point): boolean {
    const lineAngle = angleBetweenPoints(a, b);
    // Adjust the angle of the point in question to be in the frame of reference of the line
    // by choosing the minimum rotation direction
    const pointAngle = angleBetweenPoints(a, pt);
    const adjustmentAngle = lineAngle < 180 ? -lineAngle : 360 - lineAngle;
    const adjustedPointAngle = (pointAngle + adjustmentAngle + 360) % 360;

    // 0 rotation is the right half of the x-axis
    // From the frame of reference of the line, the point is above the line if it has > 180 degree clockwise rotation
    return adjustedPointAngle > 180;
}

export function arePointsSeparatedByLine(a: Point, b: Point, linePoint1: Point, linePoint2: Point): boolean {
    return isPointAboveLine(a, linePoint1, linePoint2) !== isPointAboveLine(b, linePoint1, linePoint2);
}

export function moveAlongMovement(points: Point[], percentage: number) {
    if (points.length === 2) {
        return [1, percentage];
    }

    const distances = arraySlidingWindow(points, distance);
    const totalDist = distances.reduce((acc, dist) => acc + dist, 0);
    const percentageDist = percentage * totalDist;

    let leg = 1;
    let cumulativeDistance = distances[0];
    while (cumulativeDistance < percentageDist) {
        cumulativeDistance += distances[leg];
        leg += 1;
    }

    const coveredDistance = cumulativeDistance - distances[leg - 1];
    const remainingDistance = percentageDist - coveredDistance;
    const modifiedPercentage = remainingDistance / distances[leg - 1];

    return [leg, modifiedPercentage];
}

export function getBezierControlPoints(anchors: Point[]) {
    const [firstAnchor, ...restAnchors] = anchors;
    const midpoints = arraySlidingWindow(restAnchors, getMidpoint);
    const points = [firstAnchor, ...midpoints.slice(0, -1), restAnchors[restAnchors.length - 1]];

    const controlPoints = arraySlidingWindow(points, (a, c, idx) => {
        const b = restAnchors[idx];

        const controlPoint = getBezierControlPoint(a, b, c);
        const startAngle = angleBetweenPoints(a, controlPoint);
        const endAngle = angleBetweenPoints(c, controlPoint);

        return {
            controlPoint,
            startAngle,
            endAngle,
        };
    });

    const controlPointCorrections = arraySlidingWindow(controlPoints, (a, b) => {
        const angleDiff = 180 - ((360 + b.startAngle - a.endAngle) % 360);

        return angleDiff / 2;
    });

    const correctedControlPoints = controlPoints.map((ctrl, idx) => {
        const correction = controlPointCorrections[idx] ?? 0;
        const anchorPoint = midpoints[idx];

        if (!anchorPoint) {
            return ctrl.controlPoint;
        }

        return rotateAroundOrigin(anchorPoint, ctrl.controlPoint, -correction);
    });

    const correctedPoints = points.map((p, idx) => {
        if (idx === 0 || idx === points.length - 1) {
            return p;
        }

        const correction = controlPointCorrections[idx - 1] ?? 0;

        const anchorPoint = restAnchors[idx - 1];

        return rotateAroundOrigin(anchorPoint, p, -correction);
    });

    return {
        correctedPoints,
        correctedControlPoints,
    };
}

export function calculatePartialMovement(move: Move, percentage = 1): Point {
    if (move.anchors.length === 1) {
        return moveTowardsPointPercentage(move.origin, move.anchors[0], percentage);
    }

    if (move.curved) {
        const { correctedPoints, correctedControlPoints } = getBezierControlPoints([move.origin, ...move.anchors]);
        const [leg, remainingPercentage] = moveAlongMovement(correctedPoints, percentage);

        const start = correctedPoints[leg - 1];
        const control = correctedControlPoints[leg - 1];
        const end = correctedPoints[leg];

        return getPointAlongBezier(start, control, end, remainingPercentage);
    }

    const points = [move.origin, ...move.anchors];
    const [leg, remainingPercentage] = moveAlongMovement(points, percentage);
    const startPoint = points[leg - 1];
    const endPoint = points[leg];

    return moveTowardsPointPercentage(startPoint, endPoint, remainingPercentage);
}

export function createDefaultGoals(PlayConfig: PlayConfigSpec, play: any) {
    const { FieldTypes, FieldSettings } = PlayConfig;

    if (play.fieldType === FieldTypes.CUSTOM) {
        return { pieces: [], goalies: [] };
    }

    const { goals = [] } = FieldSettings[play.sport]?.[play.fieldType] ?? {};

    const pieces = goals.map((goal: any, idx) => ({
        ...goal,
        type: ToolTypes.GOAL,
        id: idx,
    }));
    const goalies = pieces.map((goal) => ({
        type: goal.goalieType,
        pieceId: goal.id,
        id: goal.id,
        origin: goal.origin,
    }));

    return { pieces, goalies };
}

export function createDefaultBalls(PlayConfig: PlayConfigSpec, play: any) {
    if (PlayConfig.NewPlaySettings.InitialBalls) {
        return PlayConfig.NewPlaySettings.InitialBalls.map((ballDef, idx) => {
            const player = play.initial.find((plr) => plr.role === ballDef.role);

            return {
                id: idx,
                carrier: player.id,
            };
        });
    }

    return [];
}

export function createDefaultPlayers(PlayConfig: PlayConfigSpec, play: any): any[] {
    const { NewPlaySettings, FieldTypes, FieldSettings, MoveConfig } = PlayConfig;

    if (NewPlaySettings.StartingFormation) {
        const { layout } = MoveConfig[NewPlaySettings.StartingFormation];
        const { width, height } =
            play.fieldType === FieldTypes.CUSTOM ? play.fieldConfig : FieldSettings[play.sport][play.fieldType];

        return getPresetCoords(layout, { x: width / 2, y: height / 2 }, width).map((coords, idx) => {
            return {
                id: idx,
                origin: coords,
                type: layout[idx].type,
                ...(layout[idx].props ?? {}),
            };
        });
    }

    return [];
}

export function getFieldViewport(players: any[], moves: any[]) {
    let minX = 99999;
    let minY = 99999;
    let maxX = 0;
    let maxY = 0;

    const checkBounds = (item: any) => {
        if (isNaN(item.x) || isNaN(item.y)) {
            return;
        }

        maxX = Math.max(maxX, item.x);
        maxY = Math.max(maxY, item.y);

        minX = Math.min(minX, item.x);
        minY = Math.min(minY, item.y);
    };

    players.forEach((plr) => checkBounds(plr.origin));

    moves.forEach((mv: any) => {
        mv.anchors.forEach(checkBounds);
    });

    const width = maxX - minX;
    const height = maxY - minY;
    const padding = Math.max(height * 0.2, 3);

    return {
        top: minY - padding,
        left: minX - padding,
        width: width + padding * 2,
        height: height + padding * 2,
    };
}

export function createLines(move: Move) {
    const lines = [];

    lines.push({
        a: {
            x: move.origin.x,
            y: move.origin.y,
        },
        b: {
            x: move.anchors[0].x,
            y: move.anchors[0].y,
        },
        move,
    });

    if (move.anchors.length > 1) {
        lines.push({
            a: {
                x: move.anchors[0].x,
                y: move.anchors[0].y,
            },
            b: {
                x: move.anchors[1].x,
                y: move.anchors[1].y,
            },
            move,
        });
    }

    return lines;
}

export function defaultMove(field: Field, target: Point | FieldPiece, type: string, targetId?: number): Move {
    const origin = target instanceof FieldPiece ? target.origin : target;
    const mv = new Move({
        type,
        target: targetId,
        origin: { ...origin },
    });

    mv.anchors = [
        {
            x: origin.x + 5,
            y: origin.y - 5,
        },
    ].map((anc: any) => {
        return field.clampCoords(anc);
    });

    return mv;
}

export function standstillMove(target: FieldPiece) {
    return new Move({
        target: target.id,
        origin: { ...target.origin },
        type: ToolTypes.MOVE,
        anchors: [],
    });
}

export function convertToUnifiedField(PlayConfig: PlayConfigSpec, play: PlayData) {
    const { PlayTypes, FieldSettings } = PlayConfig;

    const newPlay = {
        ...play,
        unifiedField: true,
    };

    if (play.fieldType !== PlayTypes.HALF_FIELD) {
        return newPlay;
    }

    const fieldSettings = FieldSettings[play.sport][PlayTypes.HALF_FIELD];
    const offset = { x: 0, y: fieldSettings.height };

    newPlay.initial = newPlay.initial.map((plr: any) => ({
        ...plr,
        origin: applyOffset(plr.origin, offset),
    }));

    if (newPlay.fieldPieces) {
        newPlay.fieldPieces = newPlay.fieldPieces.map((piece) => ({
            ...piece,
            origin: applyOffset(piece.origin, offset),
        }));
    }

    newPlay.frames = newPlay.frames.map((frm: any) => {
        const newFrame = { ...frm };

        if (newFrame.passes) {
            newFrame.passes = newFrame.passes.map((pass) => ({
                ...pass,
                anchors: pass.anchors.map((anc) => ({ ...anc, ...applyOffset(anc, offset) })),
            }));
        }

        if (newFrame.shots) {
            newFrame.shots = newFrame.shots.map((shot) => ({
                ...shot,
                anchors: shot.anchors.map((anc) => ({ ...anc, ...applyOffset(anc, offset) })),
            }));
        }

        if (newFrame.moves) {
            newFrame.moves = newFrame.moves.map((move) => ({
                ...move,
                anchors: move.anchors.map((anc) => ({ ...anc, ...applyOffset(anc, offset) })),
            }));
        }

        if (newFrame.fieldComments) {
            newFrame.fieldComments = newFrame.fieldComments.map((comment) => ({
                ...comment,
                ...applyOffset(comment, offset),
            }));
        }

        return newFrame;
    });

    return newPlay;
}

// Mutates arguments
export function updateExistingOrCreateNew(existingItems: any[], item: any) {
    if (item.id !== undefined) {
        const existing = existingItems.find((it: any) => {
            return it.id === item.id;
        });

        if (existing) {
            Object.assign(existing, item);
        } else {
            existingItems.push(item);
        }
    } else {
        let nextId = 0;
        const findExisting = (it: any) => it.id === nextId;
        while (existingItems.find(findExisting)) nextId += 1;

        item.id = nextId;
        existingItems.push(item);
    }
}

// Mutates arguments
export function removePlayerFromFrames(frames: any[], removed: any) {
    frames.forEach((frame: any) => {
        if (frame.passes) {
            frame.passes = frame.passes.filter((pass: any) => pass.target !== removed.id);
        }

        if (frame.shots) {
            frame.shots = frame.shots.filter((shot: any) => shot.target === removed.pieceId);
        }

        if (frame.moves) {
            frame.moves = frame.moves.filter(
                (mv: any) =>
                    mv.target !== removed.id && (mv.targetLine === undefined || mv.targetLine !== removed.pieceId)
            );
        }

        if (frame.assignments) {
            frame.assignments = frame.assignments.filter(
                (asn: any) => asn.defId !== removed.id && asn.atkId !== removed.id
            );
        }

        if (frame.slides) {
            frame.slides = frame.slides.filter((sld: any) => sld.target !== removed.id);
        }

        if (frame.options) {
            if (!removed.hasBall) {
                frame.options = frame.options.filter((opt: any) => opt !== removed.id);
            } else {
                frame.options = [];
            }
        }

        if (frame.playerRotations) {
            frame.playerRotations = frame.playerRotations.filter((rot: any) => rot.target !== removed.id);
        }
    });
}

// Mutates arguments
export function removeBallMovement(play: any, ballId: number) {
    play.balls = play.balls.filter((ball: any) => ball.id !== ballId);

    play.frames.forEach((frame: any) => {
        if (frame.passes) {
            frame.passes = frame.passes.filter((pass: any) => pass.ball !== ballId);
        }

        if (frame.shots) {
            frame.shots = frame.shots.filter((shot: any) => shot.ball !== ballId);
        }
    });
}

export function getEmptyFrame() {
    return {
        moves: [] as any[],
    };
}

export function getDefaultViewOptions(PlayConfig: PlayConfigSpec) {
    return objectMerge(
        {
            ATTACK: {
                moves: true,
                roles: true,
                ids: true,
            },
            DEFENSE: {
                moves: true,
                roles: true,
                ids: true,
                assignments: true,
            },
            flipField: false,
            fieldLines: {},
        },
        PlayConfig.NewPlaySettings.ViewOptions ?? {}
    );
}

export function getNewPlay(PlayConfig: PlayConfigSpec, params: any) {
    const { PlayTypes, FeatureFlags, NewPlaySettings } = PlayConfig;
    const { type, ...playDetails } = params;

    const play: PlayData = {
        ...playDetails,
        version: 7,
        type,
        fieldSetup: true,
        fieldType: null,
        unifiedField: FeatureFlags.UnifiedField,
        balls: [],
        initial: [],
        frames: new Array(NewPlaySettings.FrameCount).fill(null).map(() => getEmptyFrame()),
        viewOptions: getDefaultViewOptions(PlayConfig),
    };

    if (type !== PlayTypes.DRILL) {
        play.fieldType = type;

        const { pieces, goalies } = createDefaultGoals(PlayConfig, play);
        play.fieldPieces = pieces;
        play.initial = goalies.concat(createDefaultPlayers(PlayConfig, play));

        play.balls = createDefaultBalls(PlayConfig, play);
    }

    return play;
}

export function getFrameDuration(sport: string, frame: FrameSnapshot): number {
    const site = getSiteForSport(sport);

    if (site === Sites.footballlab) {
        if (frame.idx === 1) {
            return 0.25;
        }

        if (frame.moves?.length === 0) {
            return 0.25;
        }
    }

    return 1;
}

export function conformPlayData(PlayConfig: PlayConfigSpec, play: any) {
    const newPlay = { ...play };

    newPlay.initial = newPlay.initial ?? [];
    newPlay.frames = newPlay.frames ?? [];

    if (!newPlay.version) {
        newPlay.version = 2;

        // Properly handle plays which were saved with the old flag name
        newPlay.fieldSetup = Boolean(newPlay.fieldSetup || newPlay.addingInitial);
        delete newPlay.addingInitial;

        if (newPlay.type !== PlayConfig.PlayTypes.DRILL) {
            newPlay.fieldType = newPlay.type;
        }

        // Ensure that field type and goals will always be set (all plays bfor non-drill plays
        const { pieces, goalies } = createDefaultGoals(PlayConfig, newPlay);
        newPlay.fieldPieces = pieces;

        newPlay.initial = newPlay.initial.map((plr: any) => {
            if (plr.type === ToolTypes.ATTACKER) return { ...plr, type: ToolTypes.BLUEPLAYER };
            if (plr.type === ToolTypes.DEFENDER) return { ...plr, type: ToolTypes.ORANGEPLAYER };
            return plr;
        });

        const [defaultGoalie, secondGoalie] = goalies;

        const existingGoalie = newPlay.initial.find((plr: any) => plr.type === ToolTypes.GOALIE);

        // If there is already a goalie, reuse the same id
        newPlay.initial = newPlay.initial.filter((plr: any) => plr.type !== ToolTypes.GOALIE);

        updateExistingOrCreateNew(newPlay.initial, {
            ...defaultGoalie,
            id: existingGoalie ? existingGoalie.id : undefined,
        });

        if (secondGoalie) {
            // Secondary goalie can always get a new id
            updateExistingOrCreateNew(newPlay.initial, {
                ...secondGoalie,
                id: undefined,
            });
        }

        // Ensure that the shoot goes to the primary goal by default
        newPlay.frames = newPlay.frames.map((frm: any) => {
            if (typeof frm.shoot === 'boolean') {
                return { ...frm, shoot: 0 };
            }

            return frm;
        });

        if (!newPlay.viewOptions) {
            newPlay.viewOptions = getDefaultViewOptions(PlayConfig);
        }
    }

    if (newPlay.version === 2) {
        newPlay.version = 3;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            if (typeof frm.pass === 'number') {
                return {
                    ...frm,
                    pass: {
                        type: ToolTypes.PASS,
                        target: frm.pass,
                    },
                };
            }

            return frm;
        });
    }

    if (newPlay.version === 3) {
        newPlay.version = 4;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            return {
                ...frm,
                moves: frm.moves.map((mv: any) => {
                    if (mv.type === PlayConfig.ToolTypes.DODGE || mv.type === PlayConfig.ToolTypes.ROLLBACK) {
                        return {
                            ...mv,
                            type: ToolTypes.OMOVE,
                        };
                    }

                    return mv;
                }),
            };
        });
    }

    if (newPlay.version === 4) {
        newPlay.version = 5;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            const newFrame = {
                ...frm,
            };

            if (newFrame.options) {
                newFrame.options = newFrame.options.map((target: number) => ({
                    type: ToolTypes.OPTION,
                    id: target,
                    target,
                }));
            }

            if (newFrame.shoot !== undefined) {
                newFrame.shoot = {
                    target: newFrame.shoot,
                    type: ToolTypes.SHOOT,
                };
            }

            return newFrame;
        });
    }

    if (newPlay.version === 5) {
        newPlay.version = 6;

        const ballCarrier = newPlay.initial.find((plr: any) => plr.hasBall);

        newPlay.initial = newPlay.initial.map((plr: any) => {
            const newPlr = { ...plr };
            delete newPlr.hasBall;

            return newPlr;
        });

        newPlay.balls = [];

        if (ballCarrier) {
            newPlay.balls.push({
                id: 0,
                carrier: ballCarrier.id,
            });

            // We need to track the holder of the ball to assign source to old options
            let currentHolder = ballCarrier.id;

            newPlay.frames = newPlay.frames.map((frm: any) => {
                const newFrame = {
                    ...frm,
                };

                newFrame.moves = newFrame.moves.map((mv: any) => {
                    if (mv.type === ToolTypes.OMOVE || mv.type === ToolTypes.DMOVE) {
                        return {
                            ...mv,
                            type: ToolTypes.MOVE,
                        };
                    }

                    return mv;
                });

                if (newFrame.options) {
                    newFrame.options = newFrame.options.map((opt: any) => ({
                        ...opt,
                        source: currentHolder,
                    }));
                }

                if (newFrame.pass) {
                    newFrame.passes = [
                        {
                            ...newFrame.pass,
                            ball: 0,
                        },
                    ];

                    currentHolder = newFrame.pass.target;

                    delete newFrame.pass;
                }

                if (newFrame.shoot) {
                    newFrame.shots = [
                        {
                            ...newFrame.shoot,
                            ball: 0,
                        },
                    ];

                    const goalie = play.pieces
                        ? play.initial.find((plr: any) => {
                              if (typeof plr.pieceId === 'number') {
                                  const piece = play.pieces.find((pc: any) => plr.pieceId === pc.id);

                                  return piece.type === ToolTypes.GOAL && piece.id === newFrame.shoot.target;
                              }

                              return false;
                          })
                        : null;

                    if (goalie) {
                        currentHolder = goalie.id;
                    }

                    delete newFrame.shoot;
                }

                return newFrame;
            });
        }
    }

    if (newPlay.version === 6) {
        newPlay.version = 7;

        newPlay.initial = newPlay.initial.map((plr: any) => {
            const newPlr = {
                origin: {
                    x: plr.x,
                    y: plr.y,
                },
                ...plr,
            };

            delete newPlr.x;
            delete newPlr.y;

            return newPlr;
        });
    }

    if (newPlay.version === 7) {
        newPlay.version = 8;

        newPlay.frames = newPlay.frames.map((frm: any) => {
            const newFrame = {
                ...frm,
            };

            if (newFrame.fieldComments) {
                newFrame.fieldComments = newFrame.fieldComments.map((comment) => ({
                    ...comment,
                    width: 150,
                    height: 50,
                }));
            }

            return newFrame;
        });
    }

    // Protect against version mismatch (newer play features with older code)
    if (newPlay.initial) {
        newPlay.initial = newPlay.initial.filter((plr: any) => plr.type in PlayConfig.ToolTypes);
    }

    if (newPlay.frames) {
        newPlay.frames = newPlay.frames.map((frame: any) => {
            const newFrame = {
                ...frame,
            };

            if (newFrame.passes) {
                newFrame.passes = newFrame.passes.filter((pass: any) => pass.type in PlayConfig.ToolTypes);
            }

            if (newFrame.shots) {
                newFrame.shots = newFrame.shots.filter((shot: any) => shot.type in PlayConfig.ToolTypes);
            }

            if (newFrame.moves) {
                newFrame.moves = newFrame.moves.filter((mv: any) => mv.type in PlayConfig.ToolTypes);
            }

            return newFrame;
        });
    }

    if (newPlay.pieces) {
        newPlay.pieces = newPlay.pieces.filter((piece: any) => piece.type in PlayConfig.ToolTypes);
    }

    return newPlay;
}

export function conformFormationData(PlayConfig: PlayConfigSpec, formation: any) {
    const newFormation = { ...formation };

    if (!formation.version) {
        formation.version = 7;

        newFormation.initial.map((plr: any) => {
            const newPlr = { ...plr };
            newPlr.origin = {
                x: plr.x,
                y: plr.y,
            };

            delete newPlr.x;
            delete newPlr.y;

            return newPlr;
        });
    }

    // Protect against version mismatch (newer play features with older code)
    if (newFormation.initial) {
        newFormation.initial = newFormation.initial.filter((plr: any) => plr.type in PlayConfig.ToolTypes);
    }

    if (newFormation.pieces) {
        newFormation.pieces = newFormation.pieces.filter((piece: any) => piece.type in PlayConfig.ToolTypes);
    }

    return newFormation;
}

export function getMovePath(move: Move, fieldViewport: FieldViewport, shorten?: number, pattern?: string): string {
    const ORIGIN = { x: 0, y: 0 };

    const pixelOrigin = fieldViewport.getPixelCoords(move.origin);
    const pixelAnchors = move.anchors.map((anc: any) => fieldViewport.getPixelCoords(anc));
    const linePoints = pixelAnchors.map((anc) => getCoordsOffset(pixelOrigin, anc));

    const angle = angleBetweenPoints(linePoints[linePoints.length - 2] ?? ORIGIN, linePoints[linePoints.length - 1]);
    const rad = angle * (Math.PI / 180);

    if (move.curved && pixelAnchors.length > 1) {
        const pixelOrigin = fieldViewport.getPixelCoords(move.origin);
        const pixelAnchors = move.anchors.map((anc: any) => fieldViewport.getPixelCoords(anc));
        const { correctedPoints, correctedControlPoints } = getBezierControlPoints([pixelOrigin, ...pixelAnchors]);

        const pathSegments = arraySlidingWindow(correctedPoints, (a, c, idx) => {
            const relativeControlPoint = getCoordsOffset(pixelOrigin, correctedControlPoints[idx]);

            const relativeA = getCoordsOffset(pixelOrigin, a);
            const relativeC = getCoordsOffset(pixelOrigin, c);

            if (pattern === 'WAVE') {
                return wavyCurvePath(relativeA, relativeControlPoint, relativeC);
            }

            return bezierCurvePath(relativeA, relativeControlPoint, relativeC);
        });

        return pathSegments.join(' ');
    }

    const shortenOffset = {
        x: -Math.cos(rad) * (shorten ?? 0),
        y: -Math.sin(rad) * (shorten ?? 0),
    };

    const pathSegments = linePoints.map((anc, idx) => {
        const prev = linePoints[idx - 1] ?? ORIGIN;

        const target = { ...anc };
        if (idx === linePoints.length - 1) {
            target.x += shortenOffset.x;
            target.y += shortenOffset.y;
        }

        if (pattern === 'WAVE') {
            return wavyLinePath(prev, target);
        }

        return linePath(prev, target);
    });

    return pathSegments.join(' ');
}

export function getFormationAnchor(
    PlayConfig: PlayConfigSpec,
    playData: PlayData,
    anchorType: string,
    pieces: any[] = []
) {
    const { FieldTypes, FieldSettings } = PlayConfig;
    const { fieldType, fieldConfig = null, sport } = playData;

    if (fieldType === FieldTypes.CUSTOM) {
        return {
            x: fieldConfig.width / 2,
            y: fieldConfig.height / 2,
        };
    }

    const goals = pieces.filter((piece) => piece.type === ToolTypes.GOAL);

    if (anchorType === ToolTypes.GOAL && goals.length === 0) {
        return FieldSettings[sport][fieldType]?.center;
    }

    return goals[0].origin;
}
