import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { verifyEmail as doVerifyEmail } from '../../store/async-actions.js';
import { RootState, Selectors } from '../../store/index.js';
import { useDispatcher } from '../../shared/hooks/index.js';

const VerifyEmail: FC = () => {
    const verifyEmail = useDispatcher(doVerifyEmail);
    const emailAction = useSelector((state: RootState) => state.auth.emailAction);
    const { oobCode } = emailAction;
    const user = useSelector(Selectors.user);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        verifyEmail(oobCode).then((result: any) => {
            if (result) {
                setSuccess(true);
            } else {
                setError(true);
            }
        });
    }, []);

    if (success) {
        return (
            <div className="p-3">
                <div className="alert alert-success">
                    Your email has been verified.{' '}
                    {user ? (
                        <span>
                            <a href="/playbook">Click here</a> to return to your playbook.
                        </span>
                    ) : (
                        <span>
                            <a href="/login">Click here</a> to login.
                        </span>
                    )}
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-3">
                <div className="alert alert-danger">This link is invalid or expired.</div>
            </div>
        );
    }

    return null;
};

export default VerifyEmail;
