import { Fragment } from 'react';

/**
 * Field dimensions:
 *  60 x 55 (yards)
 *  */
const MensHalfFieldBackdrop: FC<any> = ({ fieldViewport, backgroundImage, viewOptions, optionalLineProps }) => {
    const fieldWidth = 60;
    const fieldHeight = 55;
    const goalY = 40;

    return (
        <g className="field-backdrop">
            <g {...optionalLineProps}>
                {/* Optional field lines */}
                {viewOptions.fieldLines?.GOAL ? (
                    <line
                        x1={fieldViewport.getPixelX(0)}
                        y1={fieldViewport.getPixelY(goalY)}
                        x2={fieldViewport.getPixelX(fieldWidth)}
                        y2={fieldViewport.getPixelY(goalY)}
                    />
                ) : null}

                {viewOptions.fieldLines?.FOOTBALL ? (
                    <>
                        {[5, 10, 15, 25, 30, 35, 40, 45, 50].map((yardsFromCenter) => (
                            <Fragment key={yardsFromCenter}>
                                {/* Left */}
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 - 7)}
                                    y1={fieldViewport.getPixelY(yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 - 5)}
                                    y2={fieldViewport.getPixelY(yardsFromCenter)}
                                />

                                {/* Right */}
                                <line
                                    x1={fieldViewport.getPixelX(fieldWidth / 2 + 7)}
                                    y1={fieldViewport.getPixelY(yardsFromCenter)}
                                    x2={fieldViewport.getPixelX(fieldWidth / 2 + 5)}
                                    y2={fieldViewport.getPixelY(yardsFromCenter)}
                                />
                            </Fragment>
                        ))}
                    </>
                ) : null}
            </g>

            <image
                className="team-logo"
                xlinkHref={backgroundImage}
                width={fieldViewport.getPixelLength(fieldWidth / 2)}
                height={fieldViewport.getPixelLength(fieldWidth / 2)}
                x={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(fieldWidth * 0.75)
                        : fieldViewport.getPixelX(fieldWidth * 0.25)
                }
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight - 15) : fieldViewport.getPixelY(15)}
                transform={`rotate(${fieldViewport.flipped ? 180 : 0}, ${fieldViewport.getPixelX(
                    fieldWidth / 2
                )} ${fieldViewport.getPixelY(fieldHeight / 2)})`}
            />

            {/* Field Outline */}
            <rect
                x={fieldViewport.flipped ? fieldViewport.getPixelX(fieldWidth) : fieldViewport.getPixelX(0)}
                y={fieldViewport.flipped ? fieldViewport.getPixelY(fieldHeight) : fieldViewport.getPixelY(0)}
                width={fieldViewport.getPixelLength(fieldWidth)}
                height={fieldViewport.getPixelLength(fieldHeight)}
                fill="none"
            />

            {/* Field Lines */}
            <line
                x1={fieldViewport.getPixelX(0)}
                y1={fieldViewport.getPixelY(20)}
                x2={fieldViewport.getPixelX(fieldWidth)}
                y2={fieldViewport.getPixelY(20)}
            />

            <line
                x1={fieldViewport.getPixelX(10)}
                y1={fieldViewport.getPixelY(20)}
                x2={fieldViewport.getPixelX(10)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 10)}
                y1={fieldViewport.getPixelY(20)}
                x2={fieldViewport.getPixelX(fieldWidth - 10)}
                y2={fieldViewport.getPixelY(fieldHeight)}
            />

            {/* Center */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 + 2.5)}
                y2={fieldViewport.getPixelY(1.5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth / 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth / 2 - 2.5)}
                y2={fieldViewport.getPixelY(1.5)}
            />

            {/* Wing Lines */}
            <line
                x1={fieldViewport.getPixelX(10)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(10)}
                y2={fieldViewport.getPixelY(10)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth - 10)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth - 10)}
                y2={fieldViewport.getPixelY(10)}
            />

            {/* Substitution Area */}
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(0)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth + 2)}
                y1={fieldViewport.getPixelY(0)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(5)}
            />
            <line
                x1={fieldViewport.getPixelX(fieldWidth)}
                y1={fieldViewport.getPixelY(5)}
                x2={fieldViewport.getPixelX(fieldWidth + 2)}
                y2={fieldViewport.getPixelY(5)}
            />

            <text
                dx={
                    fieldViewport.flipped
                        ? fieldViewport.getPixelX(10) - 5
                        : fieldViewport.getPixelX(fieldWidth - 10) - 5
                }
                dy={fieldViewport.flipped ? fieldViewport.getPixelY(0) - 5 : fieldViewport.getPixelY(fieldHeight) - 5}
                textAnchor="end"
                strokeWidth="0"
                fill="#aaa"
                fontSize=".8em"
            >
                LacrosseLab.com
            </text>
        </g>
    );
};

export default MensHalfFieldBackdrop;
