import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { angleBetweenPoints } from '@labradorsports/utils';
import FieldPiece from '../models/field-piece.js';
import { getLineEndpoints } from '../utils.js';
import { Anchor, PluginContext } from './api.js';

export default {
    baseType: ToolBaseTypes.PIECE,
    types: [ToolTypes.OLINE, ToolTypes.DLINE, ToolTypes.CONE, ToolTypes.GOAL],
    specialAnchorTypes: [SpecialAnchorTypes.LINE_ROTATE],
    generateAnchors: ({ play }) => {
        const { FieldTypes, PlayTypes } = play.config;
        const anchors = [];

        if (play.fieldSetup) {
            if (play.type === PlayTypes.DRILL && play.field.pieces) {
                const targetPieces = play.field.pieces.filter(
                    (piece) =>
                        // Shadings are added separately
                        ![ToolTypes.ELLIPSE, ToolTypes.RECTANGLE].includes(piece.type) &&
                        // Filter out goals if this is not a custom field
                        (piece.type !== ToolTypes.GOAL || play.field.fieldType === FieldTypes.CUSTOM)
                );

                anchors.unshift(
                    ...targetPieces.map((piece) => ({
                        type: piece.type,
                        id: piece.id,
                        ...piece.props,
                        ...piece.origin,
                        origin: piece.origin,
                    }))
                );
            }
        }

        return anchors;
    },

    generateSelectedAnchors({ fieldViewport }, selected) {
        const anchors: Anchor[] = [];

        if (selected.type === ToolTypes.OLINE || selected.type === ToolTypes.DLINE) {
            anchors.unshift(
                ...getLineEndpoints(selected, fieldViewport).map((a, idx) => ({
                    id: selected.id,
                    origin: selected.origin,
                    ...a,
                    type: SpecialAnchorTypes.LINE_ROTATE,
                    target: idx,
                }))
            );
        }

        return anchors;
    },

    handleDragEnd: ({ api, frame }, anchor, offset, clamped) => {
        if (anchor.type === SpecialAnchorTypes.LINE_ROTATE) {
            const piece = frame.getFieldPiece(anchor.id);
            api.updateFieldPiece({
                id: anchor.id,
                rotation: angleBetweenPoints(piece.origin, offset) + (anchor.target === 0 ? 180 : 0),
            });
        } else {
            api.updateFieldPiece({ id: anchor.id, type: anchor.type, origin: clamped });
        }
    },

    handleDrag: ({ frame }, anchor, offset) => {
        const piece = frame.getFieldPiece(anchor.id);
        if (anchor.type === SpecialAnchorTypes.LINE_ROTATE) {
            frame.rotateFieldPiece(
                anchor.id,
                angleBetweenPoints(piece.origin, offset) + (anchor.target === 0 ? 180 : 0)
            );

            frame.updateLinePlayers(anchor.id, piece.origin);
        } else {
            frame.updateFieldPiece(anchor.id, offset);

            if (anchor.type === ToolTypes.OLINE || anchor.type === ToolTypes.DLINE) {
                frame.updateLinePlayers(anchor.id, offset);
            }
        }
    },

    getDefaultMove({ play }, type, offset) {
        return new FieldPiece({
            type,
            origin: play.field.clampCoords(offset),
        });
    },
} as PlayPlugin<PluginContext>;
