import { ToolTypes } from '@labradorsports/constants';
import { createLines, findClosestPiece, defaultMove } from '../utils.js';
import { Anchor, PluginContext } from './api.js';
import Move from '../models/move.js';

export default {
    types: [ToolTypes.OPTION],
    generateAnchors: ({ play, frame }) => {
        const anchors: Anchor[] = [];

        if (!play.fieldSetup && frame.options) {
            anchors.unshift(
                ...frame.options.map((opt) => {
                    return {
                        type: ToolTypes.OPTION,
                        id: opt.id,
                        ...opt.anchors[0],
                    };
                })
            );
        }

        return anchors;
    },

    generateLines: ({ play, frame }) => {
        if (!play.fieldSetup && frame.options) {
            return frame.options.flatMap(createLines);
        }

        return [];
    },

    handleDragEnd: ({ api, frame }, anchor, offset) => {
        const update: any = {
            id: anchor.id,
            type: anchor.type,
        };

        const option = frame.options?.find((opt) => opt.id === anchor.id);
        if (option) {
            update.source = option.source;
        } else if (frame.balls.length === 1) {
            const ball = frame.balls[0];
            update.source = ball.props.carrier;
        }

        if (typeof update.source === 'number') {
            const closest = frame.findClosestOption(offset, update.source);

            if (closest) {
                update.target = closest.id;
            }
        } else {
            const closestCarrier = findClosestPiece(offset, frame.hasBall());
            update.source = closestCarrier.id;
        }

        if (frame.options?.find((option) => option.source === update.source && option.target === update.target)) {
            api.removeMove(update);
        } else {
            api.updateOption(update);
        }
    },

    handleDrag({ frame }, anchor, offset) {
        const option = frame.options.find((opt) => opt.id === anchor.id);
        const closest = frame.findClosestOption(offset, option.source);

        if (closest) {
            frame.updateOption(anchor.id, closest.id);
        }
    },

    getDefaultMove({ frame }, type, offset) {
        const hasBall = frame.hasBall();
        const closestCarrier = findClosestPiece(offset, hasBall);

        if (frame.balls.length > 1) {
            return defaultMove(frame.field, closestCarrier, type);
        }

        const closest = frame.findClosestOption(offset, closestCarrier.id);

        if (!closest || !hasBall) {
            return null;
        }

        return new Move({
            type,
            origin: hasBall[0].origin,
            anchors: [closest.origin],
        });
    },
} as PlayPlugin<PluginContext>;
